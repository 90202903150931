import { Get, Post, Patch } from "./axios";

const baseUrl = "/project";
const AddProjectAPIURL = `${baseUrl}/create_project/`;
const listActiveProjects = `${baseUrl}/list_active_projects`;
const ListActiveProjectsAPIPaginatedURL = `${baseUrl}/list_active_projects`;
const listArchivedProjects = `${baseUrl}/list_archived_projects`;
const ListArchivedProjectsAPIPaginatedURL = `${baseUrl}/list_archived_projects`;
const projectDetailAPIURL = `${baseUrl}/show_project/`;
const EditProjectAPIURL = `${baseUrl}/update_project/`;
const ShowActiveProjectAPIURL = `${baseUrl}/show_active_projects/`;
const ListActiveProjectsByMember = `${baseUrl}/list_active_projects_by_member/`;
const makeProjectArchivedURL = `${baseUrl}/make_project_archived/`;
const clientActiveProjectsURL = `${baseUrl}/client_active_projects/`;
const clientArchivedProjectsURL = `${baseUrl}/client_archived_projects/`;

const ListActiveProjectsAPI = (payload: any) => {
  return Get({ url: listActiveProjects, request: payload });
};

const ListActiveProjectsAPIPaginate = (payload: any) => {
  return Get({
    url: `${ListActiveProjectsAPIPaginatedURL}?${payload}`,
    request: payload,
  });
};

const ListArchivedProjectsAPI = (payload: any) => {
  return Get({ url: listArchivedProjects, request: payload });
};

const ListArchivedProjectsAPIPaginate = (payload: any) => {
  return Get({
    url: `${ListArchivedProjectsAPIPaginatedURL}?${payload}`,
    request: payload,
  });
};

const AddProjectAPI = (payload: any) => {
  return Post({ url: AddProjectAPIURL, request: payload });
};

const ProjectsDetailAPI = (payload: any) => {
  return Get({ url: `${projectDetailAPIURL}${payload}`, request: payload });
};

const EditProjectAPI = (payload: any) => {
  return Patch({ url: `${EditProjectAPIURL}${payload.id}`, request: payload });
};

const ShowActiveProjectAPI = (payload: any) => {
  return Get({ url: `${ShowActiveProjectAPIURL}`, request: payload });
};

const ListActiveProjectsByMemberAPI = (payload: any) => {
  return Get({ url: ListActiveProjectsByMember, request: payload });
};

const makeProjectArchivedAPI = (payload: any) => {
  return Patch({ url: `${makeProjectArchivedURL}${payload}/`, request: payload });
};

const clientActiveProjectsAPI = (payload: any) => {
  return Get({ url: `${clientActiveProjectsURL}${payload.clientId}?${payload.next}`, request: payload });
};

const clientArchivedProjectsAPI = (payload: any) => {
  return Get({ url: `${clientArchivedProjectsURL}${payload.clientId}?${payload.next}`, request: payload });
};

export {
  ListActiveProjectsAPI,
  ListActiveProjectsAPIPaginate,
  ListArchivedProjectsAPI,
  ListArchivedProjectsAPIPaginate,
  AddProjectAPI,
  ProjectsDetailAPI,
  EditProjectAPI,
  ShowActiveProjectAPI,
  ListActiveProjectsByMemberAPI,
  makeProjectArchivedAPI,
  clientActiveProjectsAPI,
  clientArchivedProjectsAPI,
};
