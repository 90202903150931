import useStyles from "../../Popup/style";
import { Typography, FormControl, Select, MenuItem, Tooltip } from "@mui/material";
import { useEffect } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";

type IProjectStatus = {
  id?: number;
  name?: string;
  team_name?: string;
  pm?: string;
  designation?: string;
  team?: string;
  role?: string;
  currency?: string[] | string;
  phone?: number[];
  country?: string[] | string;
};
interface DropdownControllerProps {
  arrayData: IProjectStatus[];
  name: string;
  label: string;
  children?: React.ReactNode;
  defaultValue: number | string | any;
  dataKey: string;
  isId?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  setSelected?: any;
  value?: any;
}

const DropdownController: React.FC<DropdownControllerProps> = ({
  arrayData,
  defaultValue,
  name,
  label,
  dataKey,
  isId = false,
  isRequired = false,
  isDisabled = false,
  setSelected,
  value,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();
  const isError = errors[dataKey];
  const setValue = async (event: any, onChange: any) => {
    onChange(event.target.value);
  };
  useEffect(() => {}, [value]);
  return (
    <div className={classes.spaceBetween}>
      <Typography className={classes.label}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <FormControl className={classes.formControl}>
        <Controller
          name={dataKey}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Tooltip title={isError ? (errors[dataKey] as FieldError)?.message : ""} arrow>
              <Select
                {...field}
                onChange={(e) => {
                  if (setSelected) {
                    setSelected(e.target.value);
                  }
                  setValue(e, field.onChange);
                }}
                size="small"
                {...(value ? { value } : {})}
                className={classes.select}
                error={Boolean(isError)}
                sx={{ borderColor: isError ? "main" : "" }}
                disabled={isDisabled}
              >
                {arrayData?.map((item: any) => (
                  <MenuItem key={item.id} value={isId ? item.id : item[name]}>
                    {item[name]}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          )}
        />
      </FormControl>
    </div>
  );
};

export default DropdownController;
