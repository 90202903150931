import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  contentArea: {
    padding: "15px",
  },
  commonCard: {
    borderRadius: "8px",
    background: theme.palette.decorative.white.main,
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
  },
  navLinks: {
    height: "100%",
    padding: "0px 10px 150px 10px",
    overflow: "auto",
    paddingTop: "2em",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& li": {
      position: "relative",
      listStyle: "none",
      transition: "all 0.4s ease",
      borderRadius: "8px",
      marginBottom: "5px",
      "&:hover": {
        background: theme.palette.decorative.blue.light,
      },
      "& .icon-link": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& i": {
        height: "50px",
        minWidth: "55px",
        textAlign: "center",
        lineHeight: "50px",
        color: theme.palette.decorative.white.main,
        fontSize: "20px",
        cursor: "pointer",
        transition: "all 0.3s ease",
        position: "relative",
        borderRadius: "8px",
        marginRight: "10px",
      },
      "&.showMenu i.arrow": {
        transform: "rotate(-180deg)",
      },
      "&.close i.arrow": {
        display: "none",
      },
      "& a": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
      },
      "& .link_name": {
        fontSize: "18px",
        fontWeight: 400,
        color: theme.palette.decorative.black.main,
        transition: "all 0.4s ease",
        "&.close": {
          opacity: 0,
          pointerEvents: "none",
        },
      },
      "& .sub-menu": {
        padding: "10px 0px 10px 57px",
        background: theme.palette.decorative.blue.shade2,
        display: "none",
        borderRadius: "8px",
        "&.showMenu": {
          display: "block",
        },
        "& a": {
          color: theme.palette.decorative.white.main,
          fontSize: "15px",
          padding: "5px 0",
          whiteSpace: "nowrap",
          opacity: 0.6,
          transition: "all 0.3s ease",
        },
        "& a:hover": {
          opacity: 1,
        },
        "&.close": {
          position: "absolute",
          left: "100%",
          top: "-10px",
          margin: "0",
          padding: "10px 20px",
          borderRadius: "0 6px 6px 0",
          opacity: 0,
          pointerEvents: "none",
        },
        "&.close.showMenu": {
          top: "0",
          opacity: 1,
          pointerEvents: "auto",
          transition: "all 0.4s ease",
        },
        "& .link_name": {
          display: "none",
        },
        "&.close .link_name": {
          fontSize: "18px",
          opacity: 1,
          display: "block",
        },
      },
      "&:hover .link_name": {
        color: `${theme.palette.decorative.white.main} important`,
      },
    },
  },
  navBorder: {
    borderBottom: `.5px solid ${theme.palette.decorative.white.shade2}`,
  },
  navLink: {
    fontSize: "18px",
    padding: "1% 5%",
    fontWeight: "500px",
    color: theme.palette.decorative.black.shade1,
    border: "0",
    position: "relative",
    background: "none",
    "&.active": {
      color: theme.palette.decorative.blue.main,
      "&::before": {
        width: "100%",
        content: '""',
        position: "absolute",
        left: "0px",
        right: "0px",
        height: "4px",
        background: theme.palette.decorative.blue.shade1,
        bottom: "0px",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
      },
    },
  },
  tabPane: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  projectListDashboard: {
    display: "flex",
    border: `1px solid ${theme.palette.decorative.white.shade1}`,
    padding: "10px",
    borderRadius: "8px",
    marginTop: "10px",
    "& img": {
      width: "50px",
      height: "50px",
      marginRight: "15px",
    },
  },
  commonTag: {
    borderRadius: "3px",
    border: `1px solid ${theme.palette.decorative.white.shade2}`,
    padding: "0px 8px",
    fontSize: "12px",
    color: theme.palette.decorative.white.shade3,
  },
  HeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    color: "blue",
    width: "100%",
    marginRight: "20px",
  },
  addButton: {
    "& .MuiSvgIcon-root": {
      color: "dodgerblue",
    },
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
    color: "dodgerblue",
  },
  userNameTxt: {
    color: theme.palette.decorative.blue.main,
    fontWeight: "600",
    fontSize: "26px",
  },
  Text: {
    color: theme.palette.decorative.white.shade4,
    fontWeight: "600",
    fontSize: "26px",
    margin: "0 15px",
  },

  searchPaginateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));
