import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  root: {
    textAlign: "center",
    backgroundColor: "white",
  },
  logo: {
    textAlign: "center",
    maxWidth: "25",
    height: "20%",
    padding: "15px",
  },
  icon: {
    width: 20,
    height: 20,
  },
  design: {
    position: "absolute",
    bottom: "10px",
    left: "0px",
  },
  welcomeText: {
    fontFamily: "sans-serif",
    fontWeight: "bolder",
    fontSize: "50px",
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
  },
  textField: {
    width: "45%",
    top: "20px",
  },
  button: {
    width: "100%",
    top: "40px",
  },
  mainBox: {
    width: "100%",
  },
  main: {
    justifyContent: "center",
    display: "grid",
  },
  spaceBetween: {
    marginBlock: "16px",
  },
}));
