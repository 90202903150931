import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  clientCard: {
    width: "392px",
    border: `2px solid ${theme.palette.decorative.white.shade7}`,
    height: "60px",
    background: theme.palette.decorative.white.main,
    borderRadius: "9px",
    display: "flex",
    padding: "15px",
    gap: "12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  userImg: {
    height: "60px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    borderRadius: "5px",
    color: theme.palette.decorative.white.main,
    fontWeight: 500,
    letterSpacing: "2px",
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "16px !important",
  },
  blackText: {
    color: "darkslategray",
  },
  userTitle: {
    flexDirection: "column",
    marginTop: "0px",
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "10px",
    marginRight: "0px",
  },
  employeeCardHead: {
    display: "flex",
    // padding: "11px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  users: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noWarpf: {
    width: "257px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  cardIcon: {
    width: "18px",
    height: "18px",
    fontSize: "25px",
  },
}));
