import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    decorative: {
      blue: {
        main: "#198CCB",
        light: "#0084cd",
        shade: "#007cc5",
        shade1: "#0088c7",
        shade2: "#0065a2",
        shade3: "#0088d1",
        shade4: "#0080bf",
        shade5: "#0084c3",
        shade6: "#f3f3f3",
      },
      white: {
        main: "#fff",
        light: "#F7F8FA",
        shade1: "#dee2e6",
        shade2: "#AFBEC5",
        shade3: "#838383",
        shade4: "#11101d",
        shade5: "#8b8b8b",
        shade6: "#71777f",
        shade7: "#E6E7E9",
        shade8: "#adbec4",
      },
      black: {
        main: "#1E1E1E",
        // light: '#0084cd',
        shade1: "#383738",
      },
      semantics: {
        main: "#ff0000",
      },
      asterisk: {
        main: "#ff0000",
      },
    },
  },
});

export default theme;
