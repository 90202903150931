import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import useStyles from "./style";
import AddPopup from "../../Popup";
import { useTranslation } from "react-i18next";
import EditClient from "components/Popup/client/editClient";

const HeaderArea: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleAddNewClick = () => {
    setIsDrawerOpen(true);
  };
  const classes = useStyles();
  const { t } = useTranslation(["clientDetail", "common"]);

  return (
    <>
      <div className={classes.HeaderContent}>
        <Box component="span" className={classes.Text}>
          {t("clientDetail")}
        </Box>
        <Box>
          <IconButton className={classes.addButton} onClick={handleAddNewClick}>
            <AddCircleOutlineIcon />
            <Typography variant="body1" className={classes.headerButtonText}>
              {`${t("common:edit")} ${t("common:clientDetail")}`}
            </Typography>
          </IconButton>
        </Box>
      </div>
      <AddPopup heading={"Edit Client"} open={isDrawerOpen} onClose={handleCloseDrawer}>
        <EditClient onClose={handleCloseDrawer} />
      </AddPopup>
    </>
  );
};

export default HeaderArea;
