// BoxSx.tsx

import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { EmpListAPIPaginate } from "../../serviceHandlers/userProfileServices";
import UserCard from "./UserCard";
import useStyles from "./style";
import { useSelector } from "react-redux";
import { IsUserCreated, createdUpdatedSelector } from "store/common/createdUpdatedSlice";
import SearchComponent from "components/common/components/table/searchComponent";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";

interface UserPropInterface {
  id: number;
  profile_picture: string;
  designation: string;
  team_name: string;
  location: string;
  name: string;
  mailid: string;
}

const getEmpList = async (next: string) => {
  try {
    const response = await EmpListAPIPaginate(next);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Rethrow the error to handle it at a higher level
  }
};

const BoxSx: React.FC = () => {
  const [users, setUsers] = useState<UserPropInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [next, setNext] = useState("");
  const [searchClear, setSearchClear] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();

  const { isCreated } = useSelector(createdUpdatedSelector);

  useEffect(() => {
    fetchData();
    dispatch(IsUserCreated(false));
  }, [searchTerm, isCreated]);

  const fetchData = async () => {
    try {
      if (isCreated) {
        setLimit(10);
        setOffset(0);
        setSearchClear(true);
      }
      let response = await getEmpList(`limit=${limit}&offset=${offset}&search=${searchTerm}`);
      setNext(response?.next?.split("?")[1] || "");
      setUsers(response.results || []);
      const count = Math.ceil(response.count / limit);
      setTotalPages(count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const fetchMoreData = async () => {
    try {
      const response = await getEmpList(next);
      setNext(response?.next?.split("?")[1] || "");
      setUsers((prevUsers) => [...prevUsers, ...(response?.results || [])]);
      setCurrentPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching more data:", error);
    }
  };

  return (
    <Box className={classes.contentArea}>
      <Box className={`${classes.tabPane} ${classes.p20}`}>
        <SearchComponent onSearchChange={handleSearch} clear={searchClear} />

        <InfiniteScroll
          dataLength={users.length}
          next={fetchMoreData}
          style={{ overflow: "hidden" }}
          hasMore={currentPage < totalPages}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          <Grid container spacing={2}>
            {users?.map((projectData, index) => (
              <Grid item key={index}>
                <UserCard data={projectData} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default BoxSx;
