import React from "react";
import { Typography, FormControl, Tooltip } from "@mui/material";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { MuiTelInput } from "mui-tel-input";
import useStyles from "../../Popup/style";

interface PhoneNumberControllerProps {
  label: string;
  children?: React.ReactNode;
  defaultValue?: number | string;
  dataKey: string;
  isRequired?: boolean;
}
const PhoneNumberInput: React.FC<PhoneNumberControllerProps> = ({ defaultValue, label, dataKey, isRequired = false }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();
  const isError = errors[dataKey];
  return (
    <div className={classes.spaceBetween}>
      <Typography className={classes.label}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <FormControl className={classes.formControl}>
        <Controller
          name={dataKey}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Tooltip title={isError ? (errors[dataKey] as FieldError)?.message : ""} arrow>
              <MuiTelInput
                {...field}
                preferredCountries={["US", "CA"]} // Set your preferred countries if needed
                error={Boolean(isError)}
                sx={{ borderColor: isError ? "main" : "" }}
              />
            </Tooltip>
          )}
        />
      </FormControl>
    </div>
  );
};

export default PhoneNumberInput;
