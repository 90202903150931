import ProjectContentArea from "../../Project";
import ProjectHeaderArea from "../../Project/header";
import EmployeeHeaderArea from "../../EmployeeList/header";
import EmployeeContentArea from "../../EmployeeList";
import ListClients from "../../Client";
import ClientHeaderArea from "../../Client/header";
import EmployeeDetailHeaderArea from "../../EmployeeList/detail/header";
import EmployeeDetailContentArea from "../../EmployeeList/detail";
import { Roles } from "utils/hooks/usePermissions";
import { Header, Body } from "../AccessDenied";
import ProjectDetail from "components/Project/detail";
import TimeSheetHeaderArea from "components/TimeSheet/header";
import TimeSheetContentArea from "components/TimeSheet/index";
import TeamMemberHeaderArea from "../../TempMember/request/header";
import TeamMemberContentArea from "../../TempMember/approval/index";
import ProfileContentArea from "../../Profile";
import TimeSheetApprovalHeaderArea from "../../TimeSheetApproval/header";
import TimeSheetApprovalContentArea from "../../TimeSheetApproval/index";
import ProfileHeaderArea from "../../Profile/header";
import ClientDetailHeaderArea from "../../Client/detail/header";
import ClientDetailContentArea from "../../Client/detail";
import MyTemporaryMemberHeaderArea from "../../TempMember/request/header";
import MyTemporaryMemberContentArea from "../../TempMember/request/myTemporaryMember";
import TemporaryMemberRequestedHeaderArea from "../../TempMember/approval/header";
import TemporaryMemberRequestedContentArea from "../../TempMember/approval";

export interface DashboardConfig {
  [role: string]: {
    dashBoardHeaderArea?: React.ReactNode;
    dashBoardContentArea?: React.ReactNode;
    projectHeaderArea?: React.ReactNode;
    projectContentArea?: React.ReactNode;
    employeeHeaderArea?: React.ReactNode;
    employeeContentArea?: React.ReactNode;
    clientHeaderArea?: React.ReactNode;
    clientContentArea?: React.ReactNode;
    projectDetailContentArea?: React.ReactNode;
    timeSheetHeaderArea?: React.ReactNode;
    timeSheetContentArea?: React.ReactNode;
    temporaryHeaderArea?: React.ReactNode;
    temporaryContentArea?: React.ReactNode;
    employeeDetailHeaderArea?: React.ReactNode;
    employeeDetailContentArea?: React.ReactNode;
    profileContentArea?: React.ReactNode;
    timeSheetApprovalHeaderArea?: React.ReactNode;
    timeSheetApprovalContentArea?: React.ReactNode;
    profileHeaderArea?: React.ReactNode;
    clientDetailHeaderArea?: React.ReactNode;
    clientDetailContentArea?: React.ReactNode;
    myTemporaryMemberHeaderArea?: React.ReactNode;
    myTemporaryMemberContentArea?: React.ReactNode;
    temporaryMemberRequestedHeaderArea?: React.ReactNode;
    temporaryMemberRequestedContentArea?: React.ReactNode;
  };
}

export interface ErrorConfig {
  errorHeader: React.ReactNode;
  errorContent: React.ReactNode;
}

const dataConfig: DashboardConfig = {
  [Roles.BDM]: {
    dashBoardHeaderArea: <ProjectHeaderArea />,
    dashBoardContentArea: <ProjectContentArea />,
    projectHeaderArea: <ProjectHeaderArea />,
    projectContentArea: <ProjectContentArea />,
    employeeHeaderArea: <EmployeeHeaderArea />,
    employeeContentArea: <EmployeeContentArea />,
    clientHeaderArea: <ClientHeaderArea />,
    clientContentArea: <ListClients />,
    projectDetailContentArea: <ProjectDetail />,
    profileContentArea: <ProfileContentArea />,
    profileHeaderArea: <ProfileHeaderArea />,
    // timeSheetApprovalHeaderArea: <TimeSheetApprovalHeaderArea />,
    // timeSheetApprovalContentArea: <TimeSheetApprovalContentArea />,
    clientDetailHeaderArea: <ClientDetailHeaderArea />,
    clientDetailContentArea: <ClientDetailContentArea />,
  },
  [Roles.PM]: {
    dashBoardHeaderArea: <ProjectHeaderArea />,
    dashBoardContentArea: <ProjectContentArea />,
    projectHeaderArea: <ProjectHeaderArea />,
    projectContentArea: <ProjectContentArea />,
    employeeHeaderArea: <EmployeeHeaderArea />,
    employeeContentArea: <EmployeeContentArea />,
    clientHeaderArea: <ClientHeaderArea />,
    clientContentArea: <ListClients />,
    projectDetailContentArea: <ProjectDetail />,
    temporaryHeaderArea: <TeamMemberHeaderArea />,
    temporaryContentArea: <TeamMemberContentArea />,
    timeSheetApprovalHeaderArea: <TimeSheetApprovalHeaderArea />,
    timeSheetApprovalContentArea: <TimeSheetApprovalContentArea />,
    profileContentArea: <ProfileContentArea />,
    profileHeaderArea: <ProfileHeaderArea />,
    myTemporaryMemberHeaderArea: <MyTemporaryMemberHeaderArea />,
    myTemporaryMemberContentArea: <MyTemporaryMemberContentArea />,
    temporaryMemberRequestedHeaderArea: <TemporaryMemberRequestedHeaderArea />,
    temporaryMemberRequestedContentArea: <TemporaryMemberRequestedContentArea />,
  },
  [Roles.TeamMember]: {
    dashBoardHeaderArea: <ProjectHeaderArea />,
    dashBoardContentArea: <ProjectContentArea />,
    projectHeaderArea: <ProjectHeaderArea />,
    projectContentArea: <ProjectContentArea />,
    timeSheetHeaderArea: <TimeSheetHeaderArea />,
    timeSheetContentArea: <TimeSheetContentArea />,
    profileContentArea: <ProfileContentArea />,
    profileHeaderArea: <ProfileHeaderArea />,
    projectDetailContentArea: <ProjectDetail />,
  },
  [Roles.HR]: {
    dashBoardHeaderArea: <EmployeeHeaderArea />,
    dashBoardContentArea: <EmployeeContentArea />,
    employeeHeaderArea: <EmployeeHeaderArea />,
    employeeContentArea: <EmployeeContentArea />,
    employeeDetailHeaderArea: <EmployeeDetailHeaderArea />,
    employeeDetailContentArea: <EmployeeDetailContentArea />,
    profileContentArea: <ProfileContentArea />,
    profileHeaderArea: <ProfileHeaderArea />,
  },
};

const errorConfig: ErrorConfig = {
  errorHeader: <Header />,
  errorContent: <Body />,
};

export { dataConfig, errorConfig };
