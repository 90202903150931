import { Get, Patch, Post } from "./axios";
const baseUrl = "/client";
const allClientApiURL = `${baseUrl}/get_all_clients/`;
const AddClientAPIURL = `${baseUrl}/add_client/`;
const listClientByBdmURL = `${baseUrl}/view_clients_by_bdm/`;
const getClientByIdURL = `${baseUrl}/get_clients/`;
const EditClientURL = `${baseUrl}/update_client/`;

const AllClientAPI = (payload: any) => {
  return Get({ url: allClientApiURL, request: payload });
};

const AddClientAPI = (payload: any) => {
  return Post({ url: AddClientAPIURL, request: payload });
};

const listClientByBdmAPI = (payload: any) => {
  return Get({ url: `${listClientByBdmURL}?${payload}`, request: payload });
};
const getClientByIdAPI = (payload: any) => {
  return Get({ url: `${getClientByIdURL}${payload}`, request: payload });
};

const EditClientAPI = (payload: any) => {
  return Patch({ url: `${EditClientURL}${payload.id}/`, request: payload });
};

export { AllClientAPI, AddClientAPI, listClientByBdmAPI, getClientByIdAPI, EditClientAPI };
