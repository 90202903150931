import React, { SetStateAction, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import useStyles from "../style";
import InputController from "../../common/components/input";
import DropdownController from "../../common/components/dropDown";
import schema from "./schema";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "../../common/useSnack";
import { openSnack } from "store/common/appSlice";
import { useDispatch } from "react-redux";
import ButtonController from "components/common/components/button";
import { format } from "date-fns";
import { ShowActiveProjectAPI, ListActiveProjectsByMemberAPI, ProjectsDetailAPI } from "serviceHandlers/projectServices";
import { useParams } from "react-router-dom";
import { usePermissions } from "utils/hooks/usePermissions";
import Box from "@mui/material/Box";
import { IsUserCreated } from "store/common/createdUpdatedSlice";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import EnterToFindModal from "components/common/components/enterToFindModal";
import { AllDepAPI } from "serviceHandlers/userTeamServices";
import { AllPmAPI, EmpListAPI } from "serviceHandlers/userProfileServices";
import DatePickerController from "components/common/components/datePicker";
import { addTemporaryTeamMemberAPI, selectTemporaryTeamMemberAPI } from "serviceHandlers/teamsTemporary";

interface ProjectData {
  type?: string;
  project_name?: string;
  team_name?: string;
  pm?: string;
}
interface Team {
  id: number;
  team_name: string;
}
interface Pm {
  id: number;
  pm: string;
}
interface Project {
  id: number;
  pm: string;
}
interface Emp {
  id: number;
  profile_picture?: string;
  designation?: string;
  team_name?: string;
  location?: string;
  name: string;
  mailid?: string;
}

const AddTempMembr: React.FC<{ onClose: () => void; projectData?: any }> = ({ onClose, projectData }) => {
  const [team, setTeam] = useState<Team[]>([]);
  const [pm, setPm] = useState<Pm[]>([]);
  const [selectedEmp, setSelectedEmp] = useState<number[]>([]);
  const [employee, setEmp] = useState<Emp[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const { snackComponent } = useSnackBar();

  const { role }: { role: string } = usePermissions();

  const classes = useStyles();
  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };

  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const onSubmit = async (data: any) => {
    try {
      console.log("data", data);

      data.from_date = format(new Date(data.from_date), "yyyy-MM-dd");
      data.to_date = format(new Date(data.to_date), "yyyy-MM-dd");

      await selectTemporaryTeamMemberAPI(data).then((response) => {
        if (response.status) {
          // dispatch(IsUserCreated(true));
          showSnackbar("success", "Temporary TeamMember successfully added!");
        } else {
          showSnackbar("error", "Failed to add Temporary TeamMember. Please try again.");
        }
      });
      onClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("error", "An error occurred. Please try again.");
    }
  };

  const listAllDepAPI = async () => {
    try {
      const response = await AllDepAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllPmAPI = async () => {
    try {
      const response = await AllPmAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllActiveProjectsAPI = async () => {
    try {
      const response = await ShowActiveProjectAPI({});
      console.log("ListActiveProjectsByMemberAPI", response);

      return response;
    } catch (error) {
      console.log("error", error);
    }
  };
  const listAllEmpLAPI = async () => {
    try {
      const response = await EmpListAPI({ exclude_assigned_to: true });
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    listAllDepAPI().then((response) => {
      setTeam(response?.data);
    });
    listAllPmAPI().then((response) => {
      setPm(response?.data);
    });
    listAllActiveProjectsAPI().then((response) => {
      console.log("response", response);
      setProjects(response?.data);
    });
    listAllEmpLAPI().then((response) => {
      setEmp(response?.data);
    });
  }, []);
  const { handleSubmit } = methods;
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Box className={classes.spaceBetween}>
          <DropdownController
            arrayData={projects}
            defaultValue={projectData && projectData.id}
            name={"project_name"}
            label={"Select Project"}
            dataKey={"project"}
            isId={true}
            isRequired
            isDisabled={projectData}
          />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController
            arrayData={team}
            defaultValue={projectData && projectData.team_name}
            name={"team_name"}
            label={"Department"}
            dataKey={"team_name"}
            // isId={true}
            isRequired
          />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController
            arrayData={pm}
            defaultValue={projectData && projectData.pm}
            name={"pm"}
            label={"Reporting Manager"}
            dataKey={"pm"}
            // isId={true}
            isRequired
          />
        </Box> */}
        {role === "PM" && (
          <Box className={classes.spaceBetween}>
            <EnterToFindModal
              setSelectedEmp={setSelectedEmp}
              checkArray={employee}
              placeholder="Enter Name to find Member"
              title="Assign To"
              selectedEmp={selectedEmp}
              name="requested_for"
              label="Select Temporary Member"
            />
          </Box>
        )}
        <Box className={classes.spaceBetween}>
          <DatePickerController label={"From"} dataKey={"from_date"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <DatePickerController label={"To"} dataKey={"to_date"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <ButtonController name="submit" label="Submit" />
          {snackComponent()}
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddTempMembr;
