import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  tabPane: {
    padding: "20px",
  },

  HeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    color: "blue",
    width: "100%",
    marginRight: "20px",
  },
  addButton: {
    "& .MuiSvgIcon-root": {
      color: "dodgerblue",
    },
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
    color: "dodgerblue",
  },
  Text: {
    color: theme.palette.decorative.white.shade4,
    fontWeight: "600",
    fontSize: "26px",
    margin: "0 15px",
  },

  filterContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(2),
    },
    justifyContent: "space-between",
  },
  tableStyle: {
    minWidth: 650,
    borderCollapse: "collapse",
    width: "100%",
    "& th, td": {
      border: "1px solid #ddd",
      padding: theme.spacing(1),
      textAlign: "left",
    },
    "& th": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
      fontWeight: "bold",
    },
    "& tbody tr:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  commonCard: {
    borderRadius: "8px",
    background: theme.palette.decorative.white.main,
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
  },

  searchPaginateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    width: "90px",
  },
  timeSheetHeader: {
    display: "flex",
    justifyContent: "space-between",
    height: "50px",
    width: "98%",
  },
  timeSheetPagination: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: "13px",
  },
  timeSheetPaginationWarp: {
    display: "flex",
    justifyContent: "end",
  },
  BtnSave: {
    top: "20px",
  },
  timeSheetHead: {
    dispaly: "flex",
  },
  contentArea: {
    padding: "15px",
  },
  tableHeadRow: {
    backgroundColor: "darkgrey",
  },
}));
