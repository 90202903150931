import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import useStyles from "../style";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { temporaryTeamByPmAPI } from "serviceHandlers/teamsTemporary";

interface TempMembersInterface {
  id?: number;
  project?: number;
  project_name?: string;
  requested_for?: string;
  requested_by?: string;
  approved_by?: string;
  from_date?: string;
  to_date?: string;
}

interface RowData {
  project_name?: string;
  requested_for?: string;
  requested_by?: string;
  approved_by?: string;
  from_date?: string;
  to_date?: string;
}

const MyTemporaryMember: React.FC = () => {
  const [temporaryMembers, setTempMembers] = useState<TempMembersInterface[]>([]);

  const classes = useStyles();

  const { t } = useTranslation(["pmDashboard", "common", "projectDrawer"]);
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await temporaryTeamByPmAPI({});
        console.log("response", response);
        if (response && response.data) {
          setTempMembers(response.data);
        } else {
          console.error("Invalid response or response.data:", response);
        }
      } catch (error) {
        console.error("Error fetching project details", error);
      }
    };

    fetch();
  }, []);

  const columns = [
    { id: "Member", label: "Member", render: (row: RowData) => row.requested_for },
    { id: "Project", label: "Project", render: (row: RowData) => row.project_name },
    { id: "Project Manager", label: "Project Manager", render: (row: RowData) => row.approved_by },

    { id: "Start Date", label: "Start Date", render: (row: RowData) => row.from_date },
    { id: "End Date", label: "End Date", render: (row: RowData) => row.to_date },
  ];
  console.log("temporaryMembers", temporaryMembers);

  const rows: RowData[] = temporaryMembers
    ? temporaryMembers.map((member: RowData) => ({
        requested_for: member?.requested_for,
        project_name: member?.project_name,
        approved_by: member?.approved_by,
        from_date: member?.from_date,
        to_date: member?.to_date,
      }))
    : [];

  return (
    <>
      <Box className={classes.contentArea}>
        <Box className={classes.commonCard}>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {columns?.map((column) => <TableCell key={column.id}>{column.label}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, rowIndex) => {
                    console.log("Row Data:", row);
                    console.log("columns", columns);

                    return (
                      <TableRow key={rowIndex}>
                        {columns?.map((column) => {
                          console.log("column", column);

                          return <TableCell key={column.id}>{column.render(row)}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default MyTemporaryMember;
