import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Avatar, ListItemIcon } from "@mui/material";
import SkypeICon from "../../../assets/images/skype.svg";

interface ClientCardProps {
  data: {
    id: number;
    profile_picture: string;
    designation: string;
    team_name: string;
    location: string;
    name: string;
    email: string;
    skype: string;
  };
}

const ClientCard: React.FC<ClientCardProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation("userCard");
  const navigate = useNavigate();

  const classes = useStyles();
  const handleEdit = () => {
    navigate(`/client/${data.id}`);
  };

  return (
    <>
      {data && (
        <Box className={classes.clientCard}>
          <Box className={classes.userImg}>
            <Avatar sx={{ width: 56, height: 56, bgcolor: "#0084c3" }} variant="rounded">
              {data.profile_picture ? (
                <img src={data.profile_picture} alt="" style={{ width: "50px", height: "50px" }} />
              ) : data.name ? (
                data.name.charAt(0)
              ) : (
                "C"
              )}
            </Avatar>
          </Box>
          <Box className={classes.userTitle} onClick={handleEdit}>
            <Typography className={` ${classes.noWarpf}`} variant="h6">
              {data.name || "-"}
            </Typography>
            <Typography variant="body1">{data?.email || "-"}</Typography>
          </Box>
          <ListItemIcon style={{ alignItems: "center" }}>
            <a href={data.skype || ""}>
              <img className={classes.cardIcon} src={SkypeICon} alt="skype Icon" />
            </a>
          </ListItemIcon>
        </Box>
      )}
    </>
  );
};

export default ClientCard;
