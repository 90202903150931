import React from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { Typography, TextField, Tooltip, InputAdornment } from "@mui/material";
import useStyles from "../../Popup/style";

interface InputControllerProps {
  name: string;
  label: string;
  placeHolder: string;
  children?: React.ReactNode;
  type?: string;
  ismultiline?: boolean;
  isRequired?: boolean;
  defaultValue?: string;
  isDisabled?: boolean;
  prefix?: string;
}

const InputController: React.FC<InputControllerProps> = ({
  name,
  label,
  placeHolder,
  type,
  children,
  ismultiline = false,
  isRequired = false,
  defaultValue,
  isDisabled = false,
  prefix,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();
  const isError = errors[name];

  return (
    <>
      <Typography className={classes.label}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Tooltip title={isError ? (errors[name] as FieldError)?.message : ""} arrow>
            <TextField
              {...field}
              placeholder={placeHolder}
              InputProps={{
                startAdornment: prefix && <InputAdornment position="start">{prefix}</InputAdornment>,
                ...(children && { startAdornment: <>{children}</> }),
              }}
              type={type}
              variant="outlined"
              size="small"
              fullWidth
              multiline={ismultiline ? true : false}
              rows={ismultiline ? 3 : undefined}
              error={Boolean(isError)}
              sx={{ borderColor: isError ? "main" : "" }}
              disabled={isDisabled}
            />
          </Tooltip>
        )}
      />
    </>
  );
};

export default InputController;
