const Header = () => {
  return <div></div>;
};
const Body = () => {
  return (
    <div>
      <h1>Access Denied!</h1>
    </div>
  );
};
export { Header, Body };
