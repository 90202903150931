import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { ProjectsDetailAPI, makeProjectArchivedAPI } from "../../../serviceHandlers/projectServices";
import useStyles from "./style";
import AddPopup from "components/Popup";
import EditProject from "components/Popup/Project/editProject";
import { getDepById } from "serviceHandlers/userTeamServices";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useTranslation } from "react-i18next";
import { PmListAPI, TlListAPI, ViewProfileByIdAPI } from "../../../serviceHandlers/userProfileServices";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddTempMemb from "components/Popup/ReqTempMemb/addTempMemberToProject";
import Switch from "@mui/material/Switch";
import { usePermissions } from "utils/hooks/usePermissions";
import { Roles } from "utils/hooks/usePermissions";

interface ProjectInterface {
  [x: string]: any;
  id?: number;
  project_name?: string;
  status?: string;
  comments?: string;
  type?: string;
  start_date?: string;
  end_date?: string;
  estimate_hours?: string;
  actual_hours?: string;
  archived_project?: boolean;
  internal_project?: boolean;
  client_name?: string;
  team_name?: string;
  bdm?: string;
  pm?: string;
  tl?: string;
  bdm_name?: string;
  pm_name?: string;
  tl_name?: string;
  team?: string;
  client?: string;
  team_members?: string[];
}

const ProjectDetail: React.FC = () => {
  // const { data } = props;

  // const {
  //   project_name ,
  //   status ,
  //   type ,
  //   start_date,
  //   end_date,
  //   estimate_hours,
  //   team_name,
  //   bdm,
  //   pm,
  //   tl,
  //   team_members ,
  // } = data;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTempMemberDrawerOpen, setIsTempMemberDrawerOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [teamMember, setTeamMember] = useState<never[] | any[]>([]);
  const [tableData, setTableData] = useState<never[] | any[]>([]);
  const [project, setProject] = useState<ProjectInterface>({});
  const [isActive, setIsActive] = useState<boolean>(false);

  const { projectId } = useParams<{ projectId: string }>();

  const classes = useStyles();
  const { role }: { role: string } = usePermissions();
  const ProjectStatus = {
    Processing: "primary",
    Ongoing: "info",
    Completed: "success",
    Paused: "secondary",
    Dropped: "error",
    Closed: "default",
  };

  const handleDelete = async (id: number) => {
    try {
      // await setProject(id);
      // Update the state after successful deletion
      // setProject(project.filter((project: any) => project.id !== id));
      setProject((prevProjects) => prevProjects.filter((project: { id: number }) => project.id !== id));
    } catch (error) {
      console.log("error", error);
    }
  };
  const [tab, SetTab] = useState("active");

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await ProjectsDetailAPI(projectId);
        console.log("response", response);
        if (response && response.data) {
          const teamMemberArray = await Promise.all(
            response.data.team_members?.map(async (item: number) => {
              const profileResponse = await ViewProfileByIdAPI(item);
              return profileResponse?.data;
            }),
          );

          const timeEntriesData = [
            ["Team Members", "Activity", "Status", "Hours", "Productivity"],
            ...response.data.time_entries?.map((entry: any) => [
              teamMemberArray.find((teamMember: any) => teamMember.id === entry.user)?.first_name || "",
              entry.task_title,
              entry.is_approved ? "Approved" : "In progress",
              entry.billable_by_emp,
              entry.hours,
            ]),
          ];

          setTableData(timeEntriesData);
          setTeamMember(teamMemberArray);

          setIsActive(response.data.archived_project);
          setProject(response.data);
        } else {
          console.error("Invalid response or response.data:", response);
        }
      } catch (error) {
        console.error("Error fetching project details", error);
      }
    };

    fetchProjectDetails();
  }, [projectId, isDrawerOpen]);

  const handleEditClick = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleDeleteClick = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    window.history.back();
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleAddTempMember = () => {
    setIsTempMemberDrawerOpen(true);
  };

  const handleCloseTempDrawer = () => {
    setIsTempMemberDrawerOpen(false);
  };

  // const handleDelete = (id: number) => {
  //   try {
  //     // await deleteClient(id);
  //     // Update the state after successful deletion
  //     setProject(project.filter((project: any) => project.id !== id));
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  const { t } = useTranslation(["pmDashboard", "common"]);

  function handleWeekChange(arg0: number): void {
    throw new Error("Function not implemented.");
  }
  // console.log("tableData", tableData);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#42C970",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "#58DDEE",
    },
  }));
  const handleIsActive = async () => {
    try {
      if (isActive === false) {
        await makeProjectArchivedAPI(projectId);
        setIsActive(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("project", project);

  return (
    <>
      <Box className={classes.contentArea}>
        <Box className={classes.commonCard}>
          <Box className={classes.tabPane} id="tile-1">
            <nav>
              <Box id="nav-tab" role="tablist" className={classes.navBorder}>
                <Box className={classes.projectAvatar}>
                  <Box className={classes.heades}>
                    <Avatar sx={{ width: 56, height: 56, bgcolor: "#0084c3" }} variant="rounded">
                      {project.project_name ? project.project_name.charAt(0) : "P"}
                    </Avatar>
                    <Box>
                      <Typography className={` ${classes.noWarpf}`} variant="h6">
                        {project.project_name}
                      </Typography>
                      <Chip
                        style={{ height: "25px", width: "85px", borderRadius: "5px", color: "#838383", paddingTop: "1px" }}
                        label={project.team}
                        size="small"
                        variant="outlined"
                        className={`${classes.commonTag} ${classes.fixedWidthChip}`}
                      />
                    </Box>
                    <Box className={classes.bigLabelBox} style={{ display: "flex" }}>
                      <Typography className={classes.noWarp} variant="h6">
                        Archive
                      </Typography>
                      <Switch
                        checked={isActive}
                        onChange={handleIsActive}
                        inputProps={{ "aria-label": "controlled" }}
                        disabled={role !== Roles.BDM}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.last}>
                    <Box className={classes.bigLabelBox}>
                      <Typography className={classes.bigLabel}>{t("teamlead")}</Typography>
                      <Typography>{project.tl_name || "-"}</Typography>
                    </Box>
                    <Box className={classes.bigLabelBox}>
                      <Typography className={classes.bigLabel}>{t("promanager")}</Typography>
                      <Typography>{project.pm_name || "-"}</Typography>
                    </Box>

                    <Box className={classes.bigLabelBox}>
                      <Typography className={classes.bigLabel}>{t("members")}</Typography>
                      {/* <Typography>{project.team_members|| "-"}</Typography> */}
                      {teamMember.length > 0 ? (
                        <Box className={`${classes.teamMembersBox} ${classes.noWarp}`}>
                          {teamMember.slice(0, 3)?.map((Empdata: any) => {
                            return (
                              Empdata && (
                                <Avatar
                                  alt={Empdata.assigned_to}
                                  src={
                                    Empdata.profile_picture ? Empdata.profile_picture : `URL_FOR_YOUR_AVATAR_IMAGE_${Empdata.first_name.charAt(0)}`
                                  }
                                >
                                  {Empdata.first_name.charAt(0)}
                                </Avatar>
                              )
                            );
                          })}
                          {teamMember.length > 3 && <Typography className={classes.bodyText}>{`+${teamMember.length - 3} others`}</Typography>}
                        </Box>
                      ) : (
                        <Typography>-</Typography>
                      )}
                    </Box>

                    <Box className={classes.projectStatus}>
                      <Box className={classes.projectStatussss}>
                        <Chip
                          style={{ height: "25px", borderRadius: "5px", color: "#FFFFFF", paddingTop: "1px" }}
                          color={(ProjectStatus[project.status as keyof typeof ProjectStatus] as any) || "default"}
                          size="small"
                          variant="filled"
                          className={classes.commonTag}
                          label={
                            <Typography variant="body2" style={{ fontWeight: "bold" }}>
                              {project.status}
                            </Typography>
                          }
                        />
                      </Box>

                      <Box className={classes.projectStatus111}>
                        <Box className={classes.date}>
                          <Typography className={classes.projectDateText}>{project.start_date || "-"}</Typography>
                          <Typography className={classes.projectDateLabel}>{t("startdate")}</Typography>
                        </Box>
                        <Box className={classes.date}>
                          <Typography className={classes.projectDateText}>{project.end_date || "-"}</Typography>
                          <Typography className={classes.projectDateLabel}>{t("enddate")}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <button
                  className={`${classes.navLink} ${tab === "activity" && "active"}`}
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                  onClick={() => {
                    SetTab("activity");
                  }}
                >
                  {t("activity")}
                </button>
                <button
                  className={`${classes.navLink} ${tab === "comments" && "active"}`}
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="false"
                  onClick={() => {
                    SetTab("comments");
                  }}
                >
                  {t("comments")}
                </button>
              </Box>
            </nav>
            {/* {tab === "active" ? <ActiveProject /> : <ArchivedProject />} */}
          </Box>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar className={classes.smallCalendar} />
          </LocalizationProvider> */}
          {/* <Box className={classes.timeSheetPaginationWarp}>
            <Box className={classes.timeSheetPagination}>
              <Typography onClick={() => handleWeekChange(0)}>{t("common:thisweek")}</Typography>
              <Box>
                <ArrowBackIosIcon onClick={() => handleWeekChange(-1)} />
                <ArrowForwardIosIcon onClick={() => handleWeekChange(1)} />
              </Box>
            </Box>
          </Box> */}

          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {tableData.length > 0 &&
                      tableData[0]?.map(
                        (
                          heading:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                            | Iterable<React.ReactNode>
                            | React.ReactPortal
                            | null
                            | undefined,
                          index: React.Key | null | undefined,
                        ) => <TableCell key={index}>{heading}</TableCell>,
                      )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.length > 1 &&
                    tableData.slice(1)?.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        {row?.map(
                          (
                            cell:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                              | Iterable<React.ReactNode>
                              | React.ReactPortal
                              | null
                              | undefined,
                            cellIndex: React.Key | null | undefined,
                          ) => (
                            <TableCell key={cellIndex}>
                              {/* Assuming the last column contains LinearProgress */}
                              {cellIndex === row.length - 1 ? (
                                <BorderLinearProgress variant="determinate" value={parseInt(row[3]) < 8 ? (parseInt(row[3]) / 8) * 100 : 100} />
                              ) : (
                                // <LinearProgress color="success" variant="determinate" value={50} />
                                cell
                              )}
                            </TableCell>
                          ),
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>

      {/* <Box className={classes.projectCard}>
      <Paper elevation={3} className={classes.projectDetailsContainer}>
        <Typography variant="h5" gutterBottom>
          {project.project_name}
        </Typography>
        
        <Grid container spacing={3}>
          {Object.entries(project)?.map(([key, value]) => (
            <Grid item xs={12} sm={6} key={key}>
              <Typography variant="subtitle1">
                <strong>{key}:</strong> {value}
              </Typography>
            </Grid>
          ))}
        </Grid> */}

      {/* <Typography variant="subtitle1" className={classes.comments}>
          <strong>Comments:</strong> {project.comments}
        </Typography> */}
      <Box mt={3}>
        {(role === Roles.BDM || role === Roles.PM) && (
          <>
            <Button variant="contained" color="primary" onClick={handleEditClick} disabled={isActive}>
              {t("edit")}
            </Button>

            <Button variant="contained" color="secondary" onClick={handleDeleteClick} sx={{ marginLeft: 2 }} disabled={true}>
              {t("delete")}
            </Button>
          </>
        )}
        {role === Roles.PM && (
          <Button variant="contained" color="secondary" onClick={handleAddTempMember} sx={{ marginLeft: 2 }} disabled={isActive}>
            {t("addTempMember")}
          </Button>
        )}
      </Box>
      {/* </Paper> */}
      <AddPopup heading={"Edit Project"} open={isDrawerOpen} onClose={handleCloseDrawer}>
        <EditProject onClose={handleCloseDrawer} />
      </AddPopup>
      {/* </Box> */}
      <Dialog open={isDeleteDialogOpen} onClose={handleDeleteCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t("common:confirm")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("common:confirmmsg")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            {t("common:no")}
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            {t("common:yes")}
          </Button>
        </DialogActions>
      </Dialog>

      <AddPopup heading={t("projectDrawer:addtemp")} open={isTempMemberDrawerOpen} onClose={handleCloseTempDrawer}>
        <AddTempMemb onClose={handleCloseTempDrawer} projectData={project} />
      </AddPopup>
    </>
  );
};

export default ProjectDetail;
