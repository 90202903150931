import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  dashboardWrap: {
    background: theme.palette.decorative.white.light,
  },
  sidebar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    position: "fixed",
    top: "15px",
    left: "15px",
    bottom: "15px",
    height: "97%",
    width: "260px",
    background: theme.palette.decorative.white.main,
    zIndex: "100",
    transition: "all 0.5s ease",
    borderRadius: "8px",
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
    "&.close": {
      width: "78px",
    },
  },
  logoDetails: {
    height: "60px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: "10px",
  },
  logoIcon: {
    margin: "15px",
    minWidth: "50px",
  },
  logoName: {
    fontSize: "22px",
    color: theme.palette.decorative.white.main,
    fontWeight: 600,
    transition: "0.3s ease",
    transitionDelay: "0.1s",
    "&.close": {
      transitionDelay: "0s",
      opacity: 0,
      pointerEvents: "none",
    },
  },

  logoDetailsI: {
    // width: '27px',
    // height: '27px',
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%,-50%)',
    // fontSize: "30px",
    // color: theme.palette.decorative.white.main",
    // height: 50,
    // minWidth: 78,
    // textAlign: "center",
    // lineHeight: 50,
  },

  navLinks: {
    // height: "92%",
    padding: "8px !important",
    // overflow: "auto",
    paddingTop: "2em",
  },
  navLinksClose: {
    // overflow: "visible",
  },
  navLinksLi: {
    // position: "relative",
    listStyle: "none",
    transition: "all 0.4s ease",
    borderRadius: "8px",
    marginBottom: "5px",
    "&:hover": {
      background: theme.palette.decorative.blue.light,
    },
  },

  navLinksWebkitScrollbar: {
    display: "none",
  },

  navLinksLiIconLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  navLinksLiIconLinkClose: {
    display: "block",
  },
  navLinksLiI: {
    height: "50px",
    minWidth: "55px",
    lineHeight: "50px",
    color: theme.palette.decorative.white.main,
    fontSize: "20px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    borderRadius: "8px",
    marginRight: "10px",
  },
  navLinksLiShowMenuArrow: {
    transform: "rotate(-180deg)",
  },
  navLinksCloseIArrow: {
    display: "none",
  },
  navLinksLiA: {
    // display: "flex",
    // alignItems: "center",
    // textDecoration: "none",
  },
  navLinksLiLinkName: {
    fontSize: "18px",
    fontWeight: "500",
    color: theme.palette.decorative.black.main,
    transition: "all 0.4s ease",
    "&.close": {
      opacity: 0,
      pointerEvents: "none",
    },
    "&:hover": {
      color: `${theme.palette.decorative.white.main} !important`,
    },
  },
  navLinksLiSubMenu: {
    padding: "10px 0px 10px 57px",
    background: theme.palette.decorative.blue.shade2,
    borderRadius: "8px",
    "&.close": {
      position: "absolute",
      left: "100%",
      top: "-10px",
      padding: "10px 20px",
      opacity: 0,
      display: "block",
      pointerEvents: "none",
      transition: "0s",
    },
  },
  navLinksLiShowMenuSubMenu: {
    display: "block",
  },
  navLinksLiSubMenuA: {
    color: theme.palette.decorative.white.main,
    fontSize: "15px",
    padding: "5px 0",
    opacity: 0.6,
    transition: "all 0.3s ease",
    "&:hover": {
      opacity: 1,
    },
  },

  navLinksCloseLiSubMenu: {
    // position: "absolute",
    // left: "100%",
    // top: "-10px",
    // padding: "10px 20px",
    // opacity: 0,
    // display: "block",
    // pointerEvents: "none",
    // transition: "0s",
  },
  navLinksCloseLiHoverSubMenu: {
    top: "0",
    opacity: 1,
    pointerEvents: "auto",
  },
  navLinksLiSubMenuLinkName: {
    // display: "none",
    "&.close": {
      fontSize: "18px",
      opacity: 1,
      display: "block",
    },
  },

  navLinksLiSubMenuBlank: {
    opacity: 1,
    pointerEvents: "auto",
    background: "red",
    padding: "6px 20px 6px 16px",
    "&:hover": {
      top: "50%",
      transform: "translateY(-50%)",
    },
  },

  navLinksLiHoverSubMenuBlank: {
    // top: "50%",
    // transform: "translateY(-50%)",
  },
  profileDetails: {
    // position: "fixed",
    // bottom: "13px",
    // width: "246px",
    alignItems: "center",
    background: theme.palette.decorative.blue.shade3,
    // padding: "4px 0 ",
    transition: "all 0.5s ease",
    // left: "15px",
    borderRadius: "8px",
    // marginLeft: "7px",
    // marginBottom: "7px",
    "&.close": {
      padding: " 0 ",
      marginLeft: "-12px",
      background: "none",
      width: "78px",
    },
  },

  profileContent: {
    display: "flex",
    alignItems: "center",
  },
  profileDetailsImg: {
    width: "50px",
    height: "50px",
    paddingLeft: "12px",
    paddingBottom: "6px",
    objectFit: "cover",
    borderRadius: "8px",
    margin: "0 0 -7px -13px",
    background: theme.palette.decorative.blue.shade1,
    transition: "all 0.5s ease",
    "&.close": {
      paddingLeft: "12px",
      paddingBottom: "6px",
    },
  },

  profileDetailsText: {
    color: theme.palette.decorative.white.main,
    fontSize: "18px",
    fontWeight: 500,
    whiteSpace: "nowrap",
    "&.job": {
      fontSize: "12px",
    },
    "&.close": {
      display: "none",
    },
  },
  itemDisplayNon: {
    display: "none",
  },

  logoTxt: {
    width: "95%",
  },
  dashboardIconBefore: {
    content: "''",
    background: "url(../../assets/images/img/dashboard-icon.svg)",
    width: "27px",
    height: "27px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  projectIconBefore: {
    content: "''",
    background: "url(../../assets/images/img/project-icon.svg)",
    width: "27px",
    height: "27px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  teamIconBefore: {
    content: "''",
    background: "url(../../assets/images/img/team-icon.svg)",
    width: "25px",
    height: "25px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  reportsIconBefore: {
    "::before": {
      content: "''",
      width: "27px",
      height: "27px",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
  },
  showMenuLi: {
    background: theme.palette.decorative.blue.shade,
  },
  showMenuLinkName: {
    color: `${theme.palette.decorative.white.main} !important`,
  },
  // navLinksLiHoverLinkName: {
  //   color: '#fff !important',
  // },
  demoDiv: {
    background: theme.palette.decorative.blue.shade,
  },
  subMenuHoverLi: {
    background: "none !important",
  },
  mediaMaxWidth420: {
    sidebarCloseNavLinksLiSubMenu: {
      display: "none",
    },
  },
  ExitButton: {
    display: "flex",
    justifyContent: "end",
    transform: "scaleX(-1)",
    color: "white",
    cursor: "pointer",
  },
  profileBlock: {
    display: "flex !important",
    justifyContent: "space-between !important",
  },
}));
