import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export const clientSlice = createSlice({
  name: "client/created",
  initialState: {
    isCreated: false,
    isUpdated: false,
  },
  reducers: {
    IsClientCreated: (state, action) => {
      state.isCreated = action.payload;
    },
    IsClientUpdated: (state, action) => {
      state.isUpdated = action.payload;
    },
  },
});

export const { IsClientCreated, IsClientUpdated } = clientSlice.actions;
export const clientSelector = (state: RootState) => state.clientReducer;
export default clientSlice.reducer;
