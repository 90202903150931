import * as yup from "yup";

const schema = yup.object().shape({
  project_name: yup.string().required("Project Name is required").max(30, "Project Name must not exceed 30 characters"),
  start_date: yup.date().required("Start date is required").max(yup.ref("end_date"), "Start date must be before end date"),
  end_date: yup.date().required("End date is required").min(yup.ref("start_date"), "End date must be after start date"),
  estimate_hours: yup.string().required("Estimate hours is required"),
  // .matches(/^[1-9]\d*$/, "Estimate hours must be a valid non-zero number")
  // .max(5, "Estimate hours must not exceed 5 digits"),
  actual_hours: yup.string().required("Actual hours is required"),
  // .matches(/^[1-9]\d*$/, "Actual hours must be a valid non-zero number")
  // .max(5, "Actual hours must not exceed 5 digits"),
  client_name: yup.string().required("Client name is required").max(30, "Client name must not exceed 30 characters"),
  pm: yup.number().required("Reporting Manager is required").min(1, "Reporting Manager is required"),
  team_name: yup.number().required("Department is required").min(1, "Department is required"),
});

export default schema;
