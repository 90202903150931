import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddUserPopup from "../../Popup/User/addUser";
import TuneIcon from "@mui/icons-material/Tune";
import AddPopup from "../../Popup";
import useStyles from "../style";
import { useTranslation } from "react-i18next";
import AddTempMemb from "../../Popup/ReqTempMemb/addTempMember";

const HeaderArea: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { t } = useTranslation(["projectDrawer", "dashboard"]);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleAddNewClick = () => {
    setIsDrawerOpen(true);
  };
  const classes = useStyles();

  return (
    <>
      <div className={classes.HeaderContent}>
        <Box component="span" className={classes.Text}>
          {t("temps")}
        </Box>
        <Box>
          <IconButton className={classes.addButton} onClick={handleAddNewClick}>
            <AddCircleOutlineIcon />
            <Typography variant="body1" className={classes.bodyText}>
              {t("temp")}
            </Typography>
          </IconButton>
        </Box>
      </div>
      <AddPopup heading={t("projectDrawer:addtemp")} open={isDrawerOpen} onClose={handleCloseDrawer}>
        <AddTempMemb onClose={handleCloseDrawer} />
      </AddPopup>
    </>
  );
};

export default HeaderArea;
