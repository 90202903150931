import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useStyles from "../style";
import InputController from "../../common/components/input";
import { EditClientAPI, getClientByIdAPI } from "../../../serviceHandlers/clientServices";
import schema from "./schema";
import { getCountryDataList } from "countries-list";
import DropdownController from "components/common/components/dropDown";
import PhoneNumberInput from "components/common/components/phoneNumberInput";
import { useTranslation } from "react-i18next";
import { IsClientUpdated } from "store/client";
import { useDispatch } from "react-redux";
import { useSnackBar } from "components/common/useSnack";
import { openSnack } from "store/common/appSlice";
import { useParams } from "react-router-dom";
import FileUploadButton from "components/common/components/fileUpload";

interface ClientCardProps {
  id: number;
  name: string;
  profile_picture: string;
  country: string;
  currency: string;
  address: string;
  website: string;
  number: string;
  skype: string;
  email: string;
  others: string;
  bdm: number;
}

const EditClient: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const classes = useStyles();
  const [client, setClient] = useState<ClientCardProps | null>(null);
  const [imageUploadStatus, setImageUploadStatus] = useState<string | null>(null);

  const { clientId } = useParams<{ clientId: string }>();

  const countryNames = getCountryDataList()?.map(({ name, currency, phone }) => ({ name, currency, phone }));

  interface CurrencyInterface {
    country: string;
    currency: string;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getClientByIdAPI(clientId);

        setClient(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [clientId]);

  useEffect(() => {
    return () => {
      dispatch(IsClientUpdated(false));
    };
  }, []);

  const currency = () => {
    const result: CurrencyInterface[] = [];

    countryNames.forEach((country) => {
      const countryName = country.name;
      const currencies = country.currency;

      currencies.forEach((currency) => {
        result.push({
          country: countryName,
          currency: currency,
        });
      });
    });
    return result;
  };

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const { t } = useTranslation(["common"]);

  const { handleSubmit } = methods;
  const dispatch = useDispatch();
  const { snackComponent } = useSnackBar();

  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };

  const onSubmitError = async (error: any) => {
    showSnackbar("error", "Oops!! You've missed something. Please check once again");
    const firstErrorField = Object.keys(error)[0];
    // Focus on the first error field
    document.getElementsByName(firstErrorField)[0].focus();
    // Scroll to the first error field
    document.getElementsByName(firstErrorField)[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const onSubmit = async (data: any) => {
    try {
      if (data.profile_picture === "") {
        delete data.profile_picture;
      }
      await EditClientAPI({ id: clientId, ...data }).then((response) => {
        if (response.status) {
          dispatch(IsClientUpdated(true));
          showSnackbar("success", "Client Updated successfully!");
        } else {
          showSnackbar("error", "Failed to update Client. Please try again.");
        }
      });
      onClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("error", "An error occurred. Please try again.");
    }
  };
  console.log("client", client);

  return (
    <>
      {client && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.spaceBetween}>
              <Typography className={classes.label}>{t("upphoto")}</Typography>
              <FileUploadButton
                defaultValue={client.profile_picture}
                label="Upload file"
                name="profile_picture"
                type="file"
                onUploadStatusChange={(status) => setImageUploadStatus(status)}
              />
              {imageUploadStatus && (
                <Typography variant="caption" color="success">
                  {imageUploadStatus}
                </Typography>
              )}
            </Box>
            <Box className={classes.spaceBetween}>
              <InputController name="name" label={"Name"} placeHolder={"Name"} isRequired defaultValue={client?.name} />
            </Box>
            <Box className={classes.spaceBetween}>
              <InputController name="email" label={"Email"} placeHolder={"Email"} isRequired defaultValue={client.email} />
            </Box>
            <Box className={classes.spaceBetween}>
              <DropdownController name="name" label="Country" arrayData={countryNames} defaultValue={client.country} dataKey={"country"} isRequired />
            </Box>
            <Box className={classes.spaceBetween}>
              <DropdownController
                name="currency"
                label="Currency"
                arrayData={currency()}
                defaultValue={client.currency}
                dataKey={"currency"}
                isRequired
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <PhoneNumberInput label={"Phone"} dataKey={"number"} isRequired defaultValue={client.number} />
            </Box>
            <Box className={classes.spaceBetween}>
              <InputController name="address" label={"Address"} placeHolder={"Address"} isRequired defaultValue={client?.address} />
            </Box>
            <Box className={classes.spaceBetween}>
              <InputController name="website" label={"Website"} placeHolder={"Website"} defaultValue={client.website} />
            </Box>
            <Box className={classes.spaceBetween}>
              <InputController name="skype" label={"Skype"} placeHolder={"Skype"} defaultValue={client.skype} />
            </Box>

            <Box className={classes.spaceBetween}>
              <InputController name="others" label={"Others"} placeHolder={"Others"} defaultValue={client.others} />
            </Box>

            <Box className={classes.spaceBetween}>
              <Button type="submit" variant="contained" size="large" fullWidth className={classes.submitButton}>
                {t("common:submit")}
              </Button>
              {snackComponent()}
            </Box>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default EditClient;
