import axios from "axios";

const createInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  const token = localStorage.getItem("accesstoken");
  if (token) {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  return instance;
};

export const Post = ({ request, url }: { request: any; url: string }) => {
  const instance = createInstance();
  return instance.post(url, request);
};

export const Get = ({ request, url }: { request: any; url: string }) => {
  const instance = createInstance();
  return instance.get(url, request);
};

export const Patch = ({ request, url }: { request: any; url: string }) => {
  const instance = createInstance();
  return instance.patch(url, request);
};
