import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useStyles from "../style";
import InputController from "../../common/components/input";
import DropdownController from "../../common/components/dropDown";
import { AllDepAPI } from "../../../serviceHandlers/userTeamServices";
import { AllPmAPI, EmpListAPI } from "../../../serviceHandlers/userProfileServices";
import { AllClientAPI } from "../../../serviceHandlers/clientServices";
import { AddProjectAPI } from "../../../serviceHandlers/projectServices";
import DatePickerController from "components/common/components/datePicker";
import schema from "./schema";
import EnterToFindModal from "components/common/components/enterToFindModal";

import { useTranslation } from "react-i18next";
import { useSnackBar } from "../../../components/common/useSnack";
import { openSnack } from "store/common/appSlice";
import { useDispatch } from "react-redux";
import { IsUserCreated } from "store/common/createdUpdatedSlice";
import ButtonController from "components/common/components/button";
import { format } from "date-fns";
interface Team {
  id: number;
  team_name: string;
}
interface Pm {
  id: number;
  pm: string;
}
interface Status {
  id: number;
  name: string;
}
interface Client {
  id: number;
  name: string;
}

interface Emp {
  id: number;
  profile_picture?: string;
  designation?: string;
  team_name?: string;
  location?: string;
  name: string;
  mailid?: string;
}

const AddProject: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [team, setTeam] = useState<Team[]>([]);
  const [pm, setPm] = useState<Pm[]>([]);
  const [client, setClient] = useState<Client[]>([]);
  const [employee, setEmp] = useState<Emp[]>([]);
  const [selectedEmp, setSelectedEmp] = useState<number[]>([]);
  const [status, setStatus] = useState<Status[]>([
    { id: 1, name: "Processing" },
    { id: 2, name: "Ongoing" },
    { id: 3, name: "Completed" },
    { id: 4, name: "Paused" },
    { id: 5, name: "Dropped" },
    { id: 6, name: "Closed" },
  ]);

  const [type, setType] = useState<Status[]>([
    { id: 1, name: "Fixed" },
    { id: 2, name: "Hourly" },
  ]);
  const classes = useStyles();

  const { snackComponent } = useSnackBar();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      project_name: "",
    },
  });

  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };

  const dispatch = useDispatch();

  const { handleSubmit } = methods;
  const formatDate = (date: any) => {
    return date.split("T")[0];
  };
  const onSubmit = async (data: any) => {
    try {
      // await AddEmpAPI(data);
      data.start_date = format(new Date(data.start_date), "yyyy-MM-dd");
      data.end_date = format(new Date(data.end_date), "yyyy-MM-dd");
      await AddProjectAPI(data).then((response) => {
        if (response.status) {
          dispatch(IsUserCreated(true));
          showSnackbar("success", "Project created successfully!");
        } else {
          showSnackbar("error", "Failed to add project. Please try again.");
        }
      });
      onClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("error", "An error occurred. Please try again.");
    }
  };
  const { t } = useTranslation(["common"]);

  const listAllDepAPI = async () => {
    try {
      const response = await AllDepAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllPmAPI = async () => {
    try {
      const response = await AllPmAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllClientAPI = async () => {
    try {
      const response = await AllClientAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllEmpLAPI = async () => {
    try {
      const response = await EmpListAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    listAllDepAPI().then((response) => {
      setTeam(response?.data);
    });
    listAllPmAPI().then((response) => {
      setPm(response?.data);
    });
    listAllClientAPI().then((response) => {
      setClient(response?.data);
    });
    listAllEmpLAPI().then((response) => {
      setEmp(response?.data);
    });
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.spaceBetween}>
          <InputController name="project_name" label={"Project Name"} placeHolder={"Ex. Project x"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController arrayData={type} defaultValue={"Fixed"} name={"name"} label={"Type"} dataKey={"type"} isRequired />
        </Box>

        <Box className={classes.spaceBetween}>
          <DropdownController arrayData={client} defaultValue={1} name={"name"} label={"Client"} dataKey={"client_name"} isId={true} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController arrayData={status} defaultValue={"Processing"} name={"name"} label={"Status"} dataKey={"status"} isRequired />
        </Box>

        <Box className={classes.spaceBetween}>
          <DatePickerController label={"Start Date"} dataKey={"start_date"} isRequired disablePast />
        </Box>
        <Box className={classes.spaceBetween}>
          <DatePickerController label={"End Date"} dataKey={"end_date"} isRequired disablePast />
        </Box>

        <Box className={classes.spaceBetween}>
          <InputController name="estimate_hours" label={"Estimate Hours"} placeHolder={"Estimate Hours"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <InputController name="actual_hours" label={"Actual Hours"} placeHolder={"Actual Hours"} isRequired />
        </Box>

        <Box className={classes.spaceBetween}>
          <DropdownController
            arrayData={team}
            defaultValue={1}
            name={"team_name"}
            label={"Department"}
            dataKey={"team_name"}
            isId={true}
            isRequired
          />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController arrayData={pm} defaultValue={1} name={"pm"} label={"Reporting Manager"} dataKey={"pm"} isId={true} isRequired />
        </Box>
        {/* <Box className={classes.spaceBetween}>
          <EnterToFindModal
            setSelectedEmp={setSelectedEmp}
            checkArray={employee}
            placeholder="Enter Name to find Member"
            title="Assign To"
            selectedEmp={selectedEmp}
            name="team_members"
            label="Add Members"
          />
        </Box> */}
        <Box className={classes.spaceBetween}>
          <ButtonController name="submit" label="Submit" />
          {snackComponent()}
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddProject;
