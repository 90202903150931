import * as yup from "yup";

const schema = yup.object().shape({
  // project_name: yup.string().required("Project Name is required").max(30, "Project Name must not exceed 30 characters"),
  // start_date: yup.date().required("Start date is required").max(yup.ref("end_date"), "Start date must be before end date"),
  // end_date: yup.date().required("End date is required").min(yup.ref("start_date"), "End date must be after start date"),
  // estimate_hours: yup.string().required("Estimate hours is required"),
  // .matches(/^[1-9]\d*$/, "Estimate hours must be a valid non-zero number")
  // .max(5, "Estimate hours must not exceed 5 digits"),
  // actual_hours: yup.string().required("Actual hours is required"),
  // .matches(/^[1-9]\d*$/, "Actual hours must be a valid non-zero number")
  // .max(5, "Actual hours must not exceed 5 digits"),
  requested_for: yup.array().required("client employee is required").min(1, "At least one employee name is required"),
  // pm: yup.number().required("Project Manager id Required"),
  // team_name: yup.number().required("Team Name is Required"),
});

export default schema;
