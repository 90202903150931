import React, { useEffect, useState } from "react";
import { TextField, Box, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useStyles from "./style";
import { ClearIcon } from "@mui/x-date-pickers";

interface SearchComponentProps {
  onSearchChange: (searchTerm: string) => void;
  clear?: boolean;
}

const SearchComponent: React.FC<SearchComponentProps> = ({ onSearchChange, clear }) => {
  const [value, setValue] = useState("");

  useEffect(() => {
    handleClear();
  }, [clear]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSearchChange(event.target.value);
    setValue(event.target.value);
  };

  const classes = useStyles();

  const handleClear = () => {
    onSearchChange("");
    setValue("");
  };

  return (
    <Box className={classes.filterContainer}>
      <TextField
        label="Search"
        variant="outlined"
        value={value}
        onChange={handleSearch}
        InputProps={{
          startAdornment: <SearchIcon />,
          endAdornment: (
            <IconButton onClick={handleClear} size="small">
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default SearchComponent;
