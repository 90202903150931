import * as yup from "yup";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  country: yup.string().required("Country is required"),
  currency: yup.string().required("Currency is required"),
  address: yup.string().required("Address is required"),
  website: yup.string(),
  number: yup
    .string()
    .required("Number is required")
    .matches(/^(\+\d{1,3}?[- ]\d{1,5}[- ])/, "Must be a valid number"),
  skype: yup.string(),
  email: yup.string().email("Invalid email").required("Email is required"),
  others: yup.string(),
});

export default schema;
