import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  contentArea: {
    padding: "15px",
  },

  tabPane: {
    padding: "20px",
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    boxShadow: theme.shadows[2],
  },

  p20: {
    padding: "20px",
  },
  gridItem: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  menuIcon: {
    marginLeft: "auto",
  },
  box: {
    "& h1": {
      margin: "0 10px 20px",
      fontSize: "12px",
      color: theme.palette.decorative.white.shade5,
    },
  },
  Text: {
    color: theme.palette.decorative.white.shade4,
    fontWeight: "600",
    fontSize: "28px",
  },
  addButton: {
    "& .MuiSvgIcon-root": {
      color: "dodgerblue",
    },
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
    color: "dodgerblue",
  },
  HeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    color: "blue",
    width: "100%",
    marginRight: "20px",
  },
}));
