import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import useStyles from "./style";
import { useTranslation } from "react-i18next";

const HeaderArea: React.FC = () => {
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const handleCloseDrawer = () => {
  //   setIsDrawerOpen(false);
  // };

  // const handleAddNewClick = () => {
  //   setIsDrawerOpen(true);
  // };

  const { t } = useTranslation("timeSheet");

  const classes = useStyles();
  return (
    <>
      <div className={classes.HeaderContent}>
        <Box component="span" className={classes.Text}>
          {t("timeSheet")}
        </Box>
      </div>
    </>
  );
};

export default HeaderArea;
