import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { CardContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useStyles from "./style";
import ProjectCard from "./ProjectCard";
import { createdUpdatedSelector } from "store/common/createdUpdatedSlice";
import { ListActiveProjectsAPIPaginate, clientActiveProjectsAPI } from "../../serviceHandlers/projectServices";
// import SearchComponent from "components/common/components/table/searchComponent";
// import CustomPagination from "components/common/components/table/pagination";
import InfiniteScroll from "react-infinite-scroll-component";
import { useParams } from "react-router-dom";

interface ProjectType {
  id: number;
  project_name: string;
  status: string;
  comments: string;
  type: string;
  start_date: string;
  end_date: string;
  estimate_hours: string;
  actual_hours: string;
  archived_project: boolean;
  internal_project: boolean;
  client_name: string;
  team_name: string;
  bdm: string;
  pm: string;
  tl: string;
  team_members: string[];
}

const ActiveProject: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [activeProjects, setActiveProjects] = useState<ProjectType[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const [next, setNext] = useState("");

  const classes = useStyles();
  const { isCreated } = useSelector(createdUpdatedSelector);
  const { t } = useTranslation("projectDrawer");
  // const [sortedField, setSortedField] = useState<keyof ProjectType | null>(
  //   null
  // );
  // const [sortOrder, setSortOrder] = useState("asc");
  // const [itemsPerPage, setItemsPerPage] = useState(10);
  // const [searchTerm, setSearchTerm] = useState("");

  const ListActiveProjects = async (next?: any) => {
    try {
      const response = clientId ? await clientActiveProjectsAPI({ clientId, next }) : await ListActiveProjectsAPIPaginate(next);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error; // Rethrow the error to handle it at a higher level
    }
  };
  useEffect(() => {
    fetchActiveProjects();
  }, [isCreated]);

  const fetchActiveProjects = async () => {
    try {
      const response = await ListActiveProjects(`limit=${limit}&offset=${offset}`);
      setNext(response?.next?.split("?")[1]);
      setActiveProjects(response?.results || []);
      const count = Math.ceil(response.count / limit);
      setTotalPages(count);
    } catch (error) {
      console.error("Error fetching projects", error);
    }
  };

  // const handleSearch = (value: string) => {
  //   setSearchTerm(value);
  //   setCurrentPage(1);
  // };

  // useEffect(() => {
  //   if (isCreated) {
  //     dispatch(IsUserCreated(false));
  //     listActiveProjectsAPI().then((response) => {
  //       setActiveProjects(response?.data || []);
  //     });
  //   }
  // }, [isCreated, dispatch]);

  // const listActiveProjectsAPI = async () => {
  //   try {
  //     const response = await ListActiveProjectsAPI({});
  //     return response;
  //   } catch (error) {
  //     console.error("Error", error);
  //     throw error; // Re-throw the error to propagate it further if needed
  //   }
  // };

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const sortedUsers = activeProjects.slice().sort((a, b) => {
  //   const compareValue = (field: keyof ProjectType) => {
  //     const valueA = a[field];
  //     const valueB = b[field];

  //     if (valueA < valueB) return sortOrder === "asc" ? -1 : 1;
  //     if (valueA > valueB) return sortOrder === "asc" ? 1 : -1;
  //     return 0;
  //   };

  //   if (sortedField) {
  //     return compareValue(sortedField);
  //   }

  //   return 0;
  // });

  // const paginatedUsers = sortedUsers.slice(startIndex, endIndex);

  // const filteredUsers = paginatedUsers.filter(
  //   (user: ProjectType) =>
  //     user.project_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     user.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     user.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     user.team_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const count = Math.ceil(sortedUsers.length / itemsPerPage);

  // const handlePageChange = (newPage: number) => {
  //   setCurrentPage(newPage);
  // };

  const fetchMoreActiveProjects = async () => {
    try {
      const response = await ListActiveProjects(next);
      setNext(response?.next?.split("?")[1]);
      setActiveProjects([...activeProjects, ...(response?.results || [])]);
      setCurrentPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error fetching more data:", error);
    }
  };

  return (
    <CardContent>
      <Box className={classes.searchPaginateContainer}>
        <Box className="projects-wrap">
          {/* <SearchComponent onSearchChange={handleSearch} /> */}
          {/* <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        /> */}
          <InfiniteScroll
            dataLength={activeProjects.length}
            next={fetchMoreActiveProjects}
            style={{ overflow: "hidden" }}
            hasMore={currentPage < totalPages}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <Grid container spacing={2}>
              {activeProjects?.map((projectData, index) => (
                <Grid item key={index}>
                  <ProjectCard data={projectData} />
                </Grid>
              ))}
            </Grid>
          </InfiniteScroll>
        </Box>
      </Box>
    </CardContent>
  );
};

export default ActiveProject;
