import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useStyles from "../style";
import InputController from "../../common/components/input";
import DropdownController from "../../common/components/dropDown";
import { ListActiveProjectsByMemberAPI } from "../../../serviceHandlers/projectServices";
import DatePickerController from "components/common/components/datePicker";
import schema from "./schema";
import { useTranslation } from "react-i18next";
import { useSnackBar } from "../../../components/common/useSnack";
import { openSnack } from "store/common/appSlice";
import { useDispatch } from "react-redux";
import { IsUserCreated } from "store/common/createdUpdatedSlice";
import ButtonController from "components/common/components/button";
import { format } from "date-fns";
import { TaskListCreateAPI } from "serviceHandlers/timeSheetServices";
import TimePickerController from "components/common/components/timePickerDiffer";
import InputTimeNumberController from "../../common/components/inputTimeNumber";

interface Project {
  id: number;
  project: string;
}

const AddHour: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [projectOptions, setProjectOptions] = useState<Project[]>([]);
  const classes = useStyles();
  const { snackComponent } = useSnackBar();

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      project: "",
      date: undefined,
      productive_hr: "",
      billable_by_emp: "",
      task_title: "",
    },
  });

  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };

  const dispatch = useDispatch();
  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    try {
      data.date = format(new Date(data.date), "yyyy-MM-dd");
      await TaskListCreateAPI(data).then((response) => {
        if (response.status) {
          dispatch(IsUserCreated(true));
          showSnackbar("success", "TimeSheet created successfully!");
        } else {
          showSnackbar("error", "Failed to create TimeSheet. Please try again.");
        }
      });
      onClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("error", "An error occurred. Please try again.");
    }
  };

  const { t } = useTranslation(["common"]);
  const fetchProjects = async () => {
    try {
      const response = await ListActiveProjectsByMemberAPI({});

      setProjectOptions(response?.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(IsUserCreated(false));
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box className={classes.spaceBetween}>
          <DropdownController
            arrayData={projectOptions}
            defaultValue={1}
            name={"project_name"}
            label={"Select Project"}
            dataKey={"project"}
            isId={true}
            isRequired
          />
        </Box>
        <Box className={classes.spaceBetween}>
          <InputController name="task_title" label={"Task"} placeHolder={"Ex. Bug Fixing"} isRequired ismultiline />
        </Box>
        <Box className={classes.spaceBetween}>
          <DatePickerController label={"Select Date"} dataKey={"date"} isRequired disablePast />
        </Box>
        <Box className={classes.spaceBetween} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <InputTimeNumberController name="productive_hr" label={"Productive Hours"} placeHolder={"hh.mm"} isRequired />
          <InputTimeNumberController name="billable_by_emp" label={"Billable Hours"} placeHolder={"hh.mm"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <ButtonController name="submit" label="Submit" />
          {snackComponent()}
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddHour;
