import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import ClientCard from "./clientCard";
import useStyles from "./style";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { listClientByBdmAPI } from "serviceHandlers/clientServices";
import { clientSelector } from "store/client";
import SearchComponent from "components/common/components/table/searchComponent";

interface UserPropInterface {
  id: number;
  profile_picture: string;
  designation: string;
  team_name: string;
  location: string;
  name: string;
  email: string;
  skype: string;
}

const BoxSx: React.FC = () => {
  const [clients, setClients] = useState<UserPropInterface[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [next, setNext] = useState("");
  const [searchClear, setSearchClear] = useState(false);

  const classes = useStyles();

  const { isCreated } = useSelector(clientSelector);

  useEffect(() => {
    if (isCreated) {
      fetchData();
    }
  }, [isCreated]);

  useEffect(() => {
    fetchData();
  }, [searchTerm]);

  const fetchData = async () => {
    try {
      if (isCreated === true) {
        setLimit(10);
        setOffset(0);
        setSearchClear(true);
      }
      const response = await listClientByBdmAPI(`limit=${limit}&offset=${offset}&search=${searchTerm}`);
      setNext(response.data?.next?.split("?")[1] || "");
      setClients(response.data.results || []);
      const count = Math.ceil(response.data.count / limit);
      setTotalPages(count);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSearch = (value: string) => {
    console.log("handleSearch", handleSearch);

    setSearchClear(false);
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const fetchMoreData = async () => {
    try {
      if (next) {
        const response = await listClientByBdmAPI(next);
        setNext(response?.data?.next?.split("?")[1] || null);
        setClients((prevUsers) => [...prevUsers, ...(response?.data?.results || [])]);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error("Error fetching more data:", error);
    }
  };

  return (
    <Box className={classes.contentArea}>
      <Box className={`${classes.tabPane} ${classes.p20}`}>
        <SearchComponent onSearchChange={handleSearch} clear={searchClear} />
        <InfiniteScroll
          dataLength={clients.length}
          next={fetchMoreData}
          style={{ overflow: "hidden" }}
          hasMore={currentPage < totalPages}
          loader={next && <h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          <Grid container spacing={2}>
            {clients?.map((clientData, index) => (
              <Grid item key={index}>
                <ClientCard data={clientData} />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default BoxSx;
