import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  fullWidthChipsInput: {
    width: "100%",
  },
  drawerContent: {
    width: "500px",
    padding: "3",
    marginLeft: "20px",
    marginRight: "20px",
    height: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  drawerHeader: {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "600px",
    lineHeight: "27px",
    letterSpacing: "0em",
    textAlign: "left",
    display: "flex",
    justifyContent: "space-between",
  },
  cancelIcon: {
    cursor: "pointer",
  },

  label: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "15px",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "5",
    color: theme.palette.decorative.white.shade6,
    paddingBlock: "8px",
  },

  picker: {
    flex: "1",
  },

  formControl: {
    width: "100%",
    margin: "1",
  },
  select: {
    marginTop: "1",
  },
  submitButton: {
    marginTop: "5",
  },
  spaceBetween: {
    marginBlock: "13px",
  },
  addButton: {
    "& .MuiSvgIcon-root": {
      color: "dodgerblue",
    },
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
    color: "dodgerblue",
  },
  enterToFindTextField: { marginBottom: "16px" },
  enterToFindAvatarBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "12px",
    cursor: "pointer",
    gap: "20px",
    marginInline: "12px",
  },
  enterToFindAvatar: {
    backgroundColor: theme.palette.decorative.blue.light,
    "& .checked": {
      backgroundColor: theme.palette.decorative.white.shade6,
    },
  },
  enterToFindNameChecked: {
    color: theme.palette.decorative.blue.light,
  },
  enterToFindNameNotChecked: {
    color: theme.palette.decorative.white.shade6,
  },

  addEmp: {
    display: "flex",
    justifyContent: "space-between",
  },
  addEmpAvatar: {
    display: "flex",
    alignItems: "center",
  },
  modalHead: {
    display: "flex",
    justifyContent: "space-between",
  },
  FormGroup: {
    display: "flex",
    height: "250px",
    width: "100%",
    overflowY: "scroll",
    flexDirection: "column",
  },
  AvatarWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: "3px",
  },
}));
