import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useStyles from "../style";
import InputController from "../../common/components/input";
import DropdownController from "../../common/components/dropDown";
import { ListActiveProjectsByMemberAPI } from "../../../serviceHandlers/projectServices";
import DatePickerController from "components/common/components/datePicker";
// Import schema and other necessary dependencies
import schema from "./schema";
import { useSnackBar } from "../../../components/common/useSnack";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom"; // Added import for useParams
import { IsUserUpdated } from "store/common/createdUpdatedSlice";
import ButtonController from "components/common/components/button";
import { format } from "date-fns";
import { EditTimeEntryUserAPI } from "serviceHandlers/timeSheetServices";
import TimePickerController from "components/common/components/timePickerDiffer";
import { openSnack } from "store/common/appSlice";
import InputTimeNumberController from "../../common/components/inputTimeNumber";

interface Project {
  id: number;
  project: string;
}
interface EditHourProps {
  onClose: () => void;
  rowDatas: any;
}

const EditHour: React.FC<EditHourProps> = ({ onClose, rowDatas }) => {
  const [projectOptions, setProjectOptions] = useState<Project[]>([]);
  const classes = useStyles();
  const { snackComponent } = useSnackBar();
  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };
  const dispatch = useDispatch();
  const methods = useForm({
    // resolver: yupResolver(schema),
    defaultValues: {},
  });

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await ListActiveProjectsByMemberAPI({});
        setProjectOptions(response?.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(IsUserUpdated(false));
    };
  }, []);

  const { handleSubmit } = methods;

  const onSubmit = async (data: any) => {
    try {
      data.id = rowDatas.id;
      const response = await EditTimeEntryUserAPI(data);
      if (response.status) {
        dispatch(IsUserUpdated(true));
        showSnackbar("success", "TimeSheet updated successfully!");
      } else {
        showSnackbar("error", "Failed to update TimeSheet. Please try again.");
      }
      onClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("error", "An error occurred. Please try again.");
    }
  };

  return (
    <>
      {projectOptions.length > 0 && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.spaceBetween}>
              <DropdownController
                arrayData={projectOptions}
                name={"project_name"}
                label={"Select Project"}
                dataKey={"project_name"}
                defaultValue={rowDatas.project}
                isId={true}
                isRequired
                isDisabled
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <InputController
                name="task_title"
                label={"Task"}
                placeHolder={"Ex. Bug Fixing"}
                defaultValue={rowDatas.task_title}
                isRequired
                ismultiline
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <DatePickerController label={"Select Date"} dataKey={"date"} defaultValue={rowDatas.date} isDisabled isRequired disablePast />
            </Box>
            <Box className={classes.spaceBetween} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <InputTimeNumberController
                name="productive_hr"
                label={"Productive Hours"}
                placeHolder={"hh.mm"}
                defaultValue={rowDatas.productive_hr}
                isRequired
              />
              <InputTimeNumberController
                name="billable_by_emp"
                label={"Billable Hours"}
                placeHolder={"hh.mm"}
                defaultValue={rowDatas.billable_by_emp}
                isRequired
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <ButtonController name="submit" label="Submit" />
              {snackComponent()} {/* Moved snackComponent here */}
            </Box>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default EditHour;
