import { Get, Post } from "./axios";
const baseUrl = "/team";
const AllDepAPIURL = `${baseUrl}/get_department/`;
const getDepByIdURL = `${baseUrl}/retrieve_team/`;

const AllDepAPI = (payload: any) => {
  return Get({ url: AllDepAPIURL, request: payload });
};

const getDepById = (payload: any) => {
  return Get({
    url: `${getDepByIdURL}${payload}`,
    request: payload,
  });
};

export { AllDepAPI, getDepById };
