import React, { useState } from "react";
import Box from "@mui/material/Box";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import ActiveProject from "./active";
import ArchivedProject from "./archive";

const ContentArea: React.FC = () => {
  const [tab, setTab] = useState("active");
  const classes = useStyles();
  const { t } = useTranslation("projectDrawer");

  return (
    <Box className={classes.contentArea}>
      <Box className={classes.commonCard}>
        <Box className={classes.tabPane} id="tile-1">
          <nav>
            <Box id="nav-tab" role="tablist" className={classes.navBorder}>
              <button
                className={`${classes.navLink} ${tab === "active" && "active"}`}
                id="nav-home-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                role="tab"
                aria-controls="nav-home"
                aria-selected="true"
                onClick={() => {
                  setTab("active");
                }}
              >
                {t("activepro")}
              </button>
              <button
                className={`${classes.navLink} ${tab === "archived" && "active"}`}
                id="nav-profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
                aria-selected="false"
                onClick={() => {
                  setTab("archived");
                }}
              >
                {t("archivedpro")}
              </button>
            </Box>
          </nav>
          {tab === "active" ? <ActiveProject /> : <ArchivedProject />}
        </Box>
      </Box>
    </Box>
  );
};

export default ContentArea;
