import React, { ChangeEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { ShowActiveProjectAPI } from "../../serviceHandlers/projectServices";
import useStyles from "./style";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TimeSheetByProjectIdAPI, UpdateTimeSheetPmAPI } from "serviceHandlers/timeSheetServices";
import BasicModal from "components/common/components/modal";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

interface RowData {
  id?: number;
  members: string;
  project_name: string;
  date?: string;
  productive_hr: string;
  billable_by_pm: string;
  action?: boolean;
}
interface TimeSheetData {
  id?: number;
  members: string;
  project_name: string;
  date?: string;
  productive_hr: string;
  billable_by_pm: string;
  approved_by_pm?: boolean;
  approved_by_bd?: boolean;
}
interface ProjectData {
  id: number;
  project_name: string;
}

const ProjectDetail: React.FC = () => {
  const classes = useStyles();
  const [projects, setProjects] = useState<ProjectData[] | []>([]);
  const [error, setError] = useState<boolean>(false);
  const [timeSheets, setTimeSheets] = useState<TimeSheetData[] | []>([]);
  const [timeSheetId, setTimeSheetId] = useState<number | undefined>(undefined);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showApproveModal, setShowApproveModal] = useState<boolean>(false);
  const [billableByPm, setBillableByPm] = useState<string | null>(null);

  const fetchActiveProject = async () => {
    const project = await ShowActiveProjectAPI({});
    setProjects(project.data);
  };

  const fetchTaskList = async () => {
    try {
      await Promise.all(
        projects.flatMap(async (project) => {
          try {
            const response = await TimeSheetByProjectIdAPI(project.id);
            const timeEntries = response.data.project_details || [];

            if (timeEntries.length > 0) {
              console.log("timeEntries", timeEntries);
              const map = timeEntries
                .map((item: any) => {
                  return {
                    id: item.id,
                    members: item.user.first_name,
                    project_name: project.project_name,
                    date: item.date,
                    productive_hr: item.productive_hr,
                    billable_by_pm: item.billable_by_pm || "-",
                    approved_by_pm: item.approved_by_pm,
                    approved_by_bd: item.approved_by_bd,
                  };
                })
                .filter(Boolean);
              console.log("map", map);

              setTimeSheets(map);
            }
            return project;
          } catch (error) {
            console.error(`Error fetching time entries for project ${project.id}:`, error);
            throw error;
          }
        }),
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setTimeSheets([]);
    if (projects.length > 0) {
      fetchTaskList();
    }
  }, [projects, showEditModal === false, showApproveModal === false]);

  useEffect(() => {
    fetchActiveProject();
  }, []);

  const { t } = useTranslation(["pmDashboard", "common", "projectDrawer"]);

  const columns = [
    { id: "project_name", label: "Project", render: (row: RowData) => row.project_name },
    { id: "date", label: "Date", render: (row: RowData) => row.date },
    { id: "members", label: "Members", render: (row: RowData) => row.members },
    { id: "productive_hr", label: "Hours", render: (row: RowData) => row.productive_hr },
    { id: "billable_by_pm", label: "Approved Hours", render: (row: RowData) => row.billable_by_pm },
    {
      id: "action",
      label: "Action",
      render: (row: RowData) => (
        <>
          {row.action ? (
            <>
              <Button onClick={() => handleEditClick(row)}>Edit</Button>
              <Button onClick={() => handleApproveClick(row)}>Approve</Button>
            </>
          ) : (
            <Typography>Approved</Typography>
          )}{" "}
        </>
      ),
    },
  ];

  const rows: RowData[] = timeSheets?.map((sheet) => ({
    id: sheet.id,
    members: sheet.members,
    project_name: sheet.project_name,
    date: sheet.date,
    productive_hr: sheet.productive_hr,
    billable_by_pm: sheet.billable_by_pm,
    action: !sheet.approved_by_pm,
  }));

  const handleEditClick = (row: RowData) => {
    console.log("Edit clicked for", row);
    setTimeSheetId(row.id);
    setError(false);
    setBillableByPm(null);
    setShowEditModal(true);
  };

  const handleApproveClick = (row: RowData) => {
    console.log("row", row);

    setBillableByPm(row.productive_hr);
    setTimeSheetId(row.id);
    setShowApproveModal(true);
  };

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    const inputValue = event.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(inputValue)) {
      setBillableByPm(inputValue);
    }
  };

  const approveByPm = async () => {
    try {
      await UpdateTimeSheetPmAPI({ id: timeSheetId, billable_by_pm: billableByPm });
    } catch (error) {}
  };

  const handleEditApproval = () => {
    if (billableByPm) {
      approveByPm();
      setShowEditModal(false);
    } else {
      setError(true);
    }
    setShowApproveModal(false);
    setBillableByPm(null);
    setTimeSheetId(undefined);
  };

  return (
    <>
      <Box className={classes.contentArea}>
        <Box className={classes.commonCard}>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {columns?.map((column) => <TableCell key={column.id}>{column.label}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, rowIndex) => {
                    return (
                      <TableRow key={rowIndex}>
                        {columns?.map((column) => {
                          return <TableCell key={column.id}>{column.render(row)}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </Box>
      <BasicModal open={showEditModal} setOpen={setShowEditModal} title={"Please Enter the Hour"}>
        <Box>
          <TextField
            placeholder={"hh.mm"}
            variant="outlined"
            size="small"
            fullWidth
            value={billableByPm}
            onChange={handleTextFieldChange}
            error={Boolean(error)}
            sx={{ borderColor: error ? "main" : "" }}
          />
        </Box>
        <Box style={{ display: "flex", justifyContent: "space-around", marginBlock: "25px" }}>
          <Button variant="contained" onClick={handleEditApproval} endIcon={<DoneIcon />}>
            Submit
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowEditModal(false);
              setBillableByPm(null);
              setTimeSheetId(undefined);
              setError(false);
            }}
            endIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Box>
      </BasicModal>
      <BasicModal open={showApproveModal} setOpen={setShowApproveModal} title={"Are you sure?"}>
        <Box style={{ display: "flex", justifyContent: "space-around", marginBlock: "25px" }}>
          <Button variant="contained" onClick={handleEditApproval} endIcon={<DoneIcon />}>
            Approve
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowApproveModal(false);
              setBillableByPm(null);
              setTimeSheetId(undefined);
            }}
            endIcon={<CloseIcon />}
          >
            Cancel
          </Button>
        </Box>
      </BasicModal>
    </>
  );
};

export default ProjectDetail;
