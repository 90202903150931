import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import useStyles from "./style";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface ProjectCardProps {
  data: {
    id: number;
    project_name: string;
    status: string;
    comments: string;
    type: string;
    start_date: string;
    end_date: string;
    estimate_hours: string;
    actual_hours: string;
    archived_project: boolean;
    internal_project: boolean;
    client_name: string;
    team_name: string;
    bdm: string;
    pm: string;
    tl: string;
    team_members: string[];
  };
}

const ProjectCard: React.FC<ProjectCardProps> = (props) => {
  const { data } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation(["pmDashboard", "common"]);

  const ProjectStatus = {
    Processing: "primary",
    Ongoing: "info",
    Completed: "success",
    Paused: "secondary",
    Dropped: "error",
    Closed: "default",
  };

  const { project_name, status, type, start_date, end_date, estimate_hours, team_name, bdm, pm, tl, team_members } = data;

  const handleEdit = () => {
    navigate(`/project/${data.id}`);
  };

  return (
    <Box className={classes.projectCard}>
      <Box className={classes.projectCardHead}>
        <Box className={classes.projectAvatarAndTItle}>
          <Box className={classes.projectAvatar}>
            <Avatar sx={{ width: 56, height: 56, bgcolor: "#0084c3" }} variant="rounded">
              <Typography style={{ fontSize: "24px" }} variant="h6">
                {project_name ? project_name.charAt(0) : "P"}
              </Typography>
            </Avatar>
          </Box>
          <Box>
            <Box className={classes.projectNameStatus} onClick={handleEdit}>
              <Typography style={{ fontSize: "24px" }} className={` ${classes.noWarpf}`} variant="h6">
                {project_name || "-"}
              </Typography>
              <Box className={classes.projectStatus}>
                <Chip
                  color={(ProjectStatus[status as keyof typeof ProjectStatus] as any) || "default"}
                  size="small"
                  sx={{ width: `${72 + status.length * (10 / 4)}px`, height: "23px", borderRadious: "2px" }}
                  variant="filled"
                  className={classes.commonTag}
                  label={
                    <Typography variant="body2" style={{ fontWeight: "bold", fontSize: "14px" }}>
                      {status || "-"}
                    </Typography>
                  }
                />
              </Box>
            </Box>
            <Box className={classes.projectDateTeam}>
              <Chip
                label={
                  <Typography variant="body2" style={{ fontSize: "12px" }}>
                    {team_name || "-"}
                  </Typography>
                }
                sx={{ width: `${16 + team_name.length * (10 / 1)}px`, height: "20px" }}
                variant="outlined"
                className={`${classes.commonTag} ${classes.fixedWidthChip}`}
              />
              <Box className={classes.dateWrap}>
                <Box className={classes.date}>
                  <Typography className={classes.projectDateText} style={{ fontSize: "12px" }} variant="h4">
                    {start_date || "-"}
                  </Typography>
                  <Typography className={classes.projectDateLabel} style={{ fontSize: "12px" }} variant="h4">
                    {t("startdate")}
                  </Typography>
                </Box>
                <Box className={classes.date}>
                  <Typography className={classes.projectDateText} style={{ fontSize: "12px" }} variant="h4">
                    {end_date || "-"}
                  </Typography>
                  <Typography className={classes.projectDateLabel} style={{ fontSize: "12px" }} variant="h4">
                    {t("enddate")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.projectContent}>
        <Box className={classes.box}>
          <Box className={classes.bigLabelBox}>
            <Typography className={classes.noWarp} variant="h4">
              {pm || "-"}
            </Typography>
            <Typography className={classes.bigLabel} variant="h4">
              {t("promanager")}
            </Typography>
          </Box>

          <Box className={classes.bigLabelBox}>
            <Typography className={classes.noWarp} variant="h4">
              {estimate_hours || "-"}
            </Typography>
            <Typography className={classes.bigLabel} variant="h4">
              {t("estimatehrs")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.box}>
          <Box className={classes.bigLabelBox}>
            <Typography className={classes.noWarp} variant="h4">
              {tl || "-"}
            </Typography>
            <Typography className={classes.bigLabel} variant="h4">
              {t("teamlead")}
            </Typography>
          </Box>
          <Box className={classes.bigLabelBox}>
            <Typography className={classes.noWarp} variant="h4">
              {type || "-"}
            </Typography>
            <Typography className={classes.bigLabel} variant="h4">
              {t("protype")}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.box}>
          <Box className={classes.bigLabelBox}>
            <Typography className={classes.noWarp} variant="h4">
              {bdm || "-"}
            </Typography>

            <Typography className={classes.bigLabel} variant="h4">
              {t("bdm")}
            </Typography>
          </Box>
          <Box className={classes.bigLabelBox}>
            {team_members.length > 0 ? (
              <Box className={`${classes.teamMembersBox} ${classes.noWarp}`}>
                {team_members.slice(0, 3)?.map((Empdata: any, index: number) => {
                  return (
                    Empdata && (
                      <Box className={classes.memberAvatarWarper}>
                        <Avatar
                          sx={{ width: 32, height: 32 }}
                          className={classes.memberAvatarImg}
                          alt={Empdata.assigned_to}
                          src={Empdata.profile_picture ? Empdata.profile_picture : `URL_FOR_YOUR_AVATAR_IMAGE_${Empdata.first_name.charAt(0)}`}
                          style={{ zIndex: 3 + index, left: `${index > 0 ? `-${index * 15}px` : "0px"}` }}
                        >
                          {Empdata.first_name.charAt(0)}
                        </Avatar>
                        <Typography
                          className={`${classes.memberAvatarImgTxt} ${classes.hoverText}`}
                          style={{ marginLeft: index > 0 ? `-${index * 20}px` : `-5px`, fontSize: "12px" }}
                          variant="h4"
                        >
                          {Empdata.first_name}
                        </Typography>
                      </Box>
                    )
                  );
                })}
                {team_members.length > 3 && (
                  <Typography className={classes.bodyText} style={{ marginLeft: `-25px`, fontSize: "12px" }}>
                    {`+${team_members.length - 3} others`}{" "}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box style={{ paddingBlock: "8px" }}>
                <Typography>-</Typography>
              </Box>
            )}
            <Typography className={classes.bigLabel} variant="h4">
              {t("members")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProjectCard;
