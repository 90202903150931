import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Paper, Typography, List, ListItem, ListItemIcon, ListItemText, Collapse, Avatar, Box } from "@mui/material";
import { ExpandLess, ExpandMore, ExitToApp } from "@mui/icons-material";
import profileImg from "../../assets/images/user.png";
import logoIcon from "../../assets/images/logo-icon.png";
import logoText from "../../assets/images/logo-text.png";
import style from "./style";
import jsonData from "../common/config/routeConfig";
import useAuth from "../../utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import AddProject from "../Popup/Project/addProject";
import AddPopup from "../Popup";
import AddTempMemb from "../Popup/ReqTempMemb/addTempMember";
import AddTempMemberToProject from "../Popup/ReqTempMemb/addTempMemberToProject";

import { useTranslation } from "react-i18next";
import { ViewProfileAPI } from "serviceHandlers/userProfileServices";
import AddClient from "../Popup/client/addClient";

const myData: any = jsonData;

const SideBar: React.FC = () => {
  const navigate = useNavigate();

  const { signOut } = useAuth();
  const classes = style();
  const { dashboardClosed } = useSelector((state: any) => state.dashboard.sidebar);
  // const { role } = useSelector((state: any) => state.auth.user);
  const [openProjects, setOpenProjects] = useState<any>({});
  const [dashboardStatus, setDashboardStatus] = useState(dashboardClosed);
  const { user } = useSelector((state: any) => state.auth);
  const { role, first_name } = useSelector((state: any) => state.auth.user);
  const [isDrawerOpen, setIsDrawerOpen] = useState("");
  const [userData, setUserData] = useState<any>({});

  const { t } = useTranslation(["projectDrawer"]);

  const handleLogout = async () => {
    await signOut({
      name: first_name,
      role: user.role,
    });
  };

  useEffect(() => {
    setDashboardStatus(dashboardClosed);

    const menuObject: any = {};
    myData[role]["sidebar"].forEach((item: any) => {
      menuObject[item.menu] = false;
    });
    setOpenProjects(menuObject);
  }, [dashboardClosed, role]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await ViewProfileAPI();
        setUserData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user]);

  const handleClickProjects = (menu: any) => {
    setOpenProjects((prevOpenProjects: any) => ({
      ...prevOpenProjects,
      [menu]: !prevOpenProjects[menu],
    }));
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen("");
  };

  const handleClick = (subMenu: any) => {
    if (subMenu?.component) {
      setIsDrawerOpen(subMenu?.component);
    }
    navigate(`${subMenu.link}`);
  };

  const renderDrawerContent = () => {
    switch (isDrawerOpen) {
      case "AddProject":
        return (
          <AddPopup heading={"Add New Project"} open={true} onClose={handleCloseDrawer}>
            <AddProject onClose={handleCloseDrawer} />
          </AddPopup>
        );
      case "AddTempMember":
        return (
          <AddPopup heading={t("projectDrawer:addtemp")} open={true} onClose={handleCloseDrawer}>
            <AddTempMemb onClose={handleCloseDrawer} />
          </AddPopup>
        );
      case "AddTempMemberToProject":
        return (
          <AddPopup heading={t("projectDrawer:addtemp")} open={true} onClose={handleCloseDrawer}>
            <AddTempMemberToProject onClose={handleCloseDrawer} />
          </AddPopup>
        );
      case "AddClient":
        return (
          <AddPopup heading={"Add New Client"} open={true} onClose={handleCloseDrawer}>
            <AddClient onClose={handleCloseDrawer} />
          </AddPopup>
        );
      default:
        return <></>;
    }
  };

  const renderSubMenu = (subMenuData: any) => (
    <ListItem key={subMenuData.name} className={`${classes.navLinksLiI}`} onClick={() => handleClick(subMenuData)}>
      <ListItemText className={classes.navLinksLiSubMenuA} primary={subMenuData.name} />
    </ListItem>
  );

  const renderSideBarData = (sideBarData: any) => (
    <>
      <ListItem
        className={`${classes.navLinksLi} ${dashboardStatus && "close"}`}
        onClick={() => {
          sideBarData?.subMenu ? handleClickProjects(sideBarData.menu) : handleClick(sideBarData);
        }}
      >
        <ListItemIcon>
          <img className={classes.logoDetailsI} src={sideBarData.icon} alt={`${sideBarData.menu} Icon`} />
        </ListItemIcon>
        <ListItemText className={`${classes.navLinksLiLinkName} ${dashboardStatus && "close"}`} primary={sideBarData.menu} />
        {!dashboardStatus && sideBarData?.subMenu && (openProjects[sideBarData.menu] ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>

      {sideBarData?.subMenu && (
        <Collapse in={openProjects ? openProjects[sideBarData.menu] : false} timeout="auto" unmountOnExit>
          <List component="div" className={`${classes.navLinksLiSubMenu} ${dashboardStatus && "close"}`} disablePadding>
            <ListItem className={`${classes.navLinksLiI}`} onClick={() => handleClick(sideBarData)}>
              <ListItemText
                className={`${classes.navLinksLiSubMenuA} ${classes.navLinksLiSubMenuLinkName} ${dashboardStatus && "close"}`}
                primary={sideBarData.menu}
              />
            </ListItem>
            {sideBarData.subMenu?.map(renderSubMenu)}
          </List>
        </Collapse>
      )}
    </>
  );

  return (
    <>
      <Paper className={`${classes.sidebar} ${dashboardStatus && "close"}`}>
        <Box>
          <div className={classes.logoDetails}>
            <img className={classes.logoIcon} src={logoIcon} alt="Logo Icon" />
            <span className={`${classes.logoName} ${dashboardStatus && "close"}`}>
              <img className={classes.logoTxt} src={logoText} alt="Logo Text" />
            </span>
          </div>

          <List className={classes.navLinks}>
            <Box>{myData[role]["sidebar"]?.map(renderSideBarData)}</Box>
          </List>
        </Box>
        <List className={classes.navLinks}>
          <Box className={`${classes.profileDetails} ${dashboardStatus && "close"}`}>
            <ListItem className={classes.profileBlock}>
              <div className={`${classes.profileContent} ${dashboardStatus && "close"}`}>
                <Avatar sx={{ width: 50, height: 50, borderRadius: "20%", border: "3px solid white" }} variant="rounded">
                  <img
                    className={`${classes.profileDetailsImg} ${dashboardStatus && "close"}`}
                    src={userData?.profile_picture || profileImg}
                    alt="Report Icon"
                  />
                </Avatar>
              </div>
              <div>
                <Typography variant="h6" className={`${classes.profileDetailsText} ${dashboardStatus && "close"}`}>
                  {userData.first_name}
                </Typography>
                <Typography variant="subtitle1" className={`${classes.profileDetailsText} job ${dashboardStatus && "close"}`}>
                  {userData.role}
                </Typography>
              </div>
              {!dashboardStatus && <ExitToApp className={classes.ExitButton} onClick={handleLogout} />}
            </ListItem>
          </Box>
        </List>
      </Paper>
      {renderDrawerContent()}
    </>
  );
};

export default SideBar;
