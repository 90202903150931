import { Grid } from "@mui/material";
import laptop from "../../assets/images/login-right-img.png";
import React from "react";

export const RightPane = () => {
  return (
    <Grid item xs={6}>
      <img src={laptop} alt="" style={{ borderRadius: "15px", width: "98%", height: 600 }} />
    </Grid>
  );
};
