import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "../../store/auth/userSlice";
import { LoginAPI } from "../../serviceHandlers/userAuthServices";
import { onSignInSuccess, onSignOutSuccess } from "../../store/auth/sessionSlice";
import { useNavigate } from "react-router-dom";
import useQuery from "./useQuery";
import dayjs from "dayjs";

function useAuth() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { token, signedIn } = useSelector((state: any) => state.auth.session);
  // const { role, first_name } = useSelector((state: any) => state.auth.user);
  let isTokenExpired = false;

  const decodeJwt = (token: string) => {
    try {
      const segments = token.split(".");
      return JSON.parse(atob(segments[1]));
    } catch (error) {
      isTokenExpired = true;
    }
  };

  const isExpired = (dateToCheck: number) => {
    const currentDate = dayjs();
    const providedDate = dayjs.unix(dateToCheck);
    return currentDate.isAfter(providedDate);
  };

  const payload = decodeJwt(token);
  isTokenExpired = payload ? isExpired(payload.exp) : isTokenExpired;

  const signIn = async (values: any) => {
    try {
      const resp = await LoginAPI(values);

      if (resp.data) {
        const token = resp.data.access;
        localStorage.setItem("accesstoken", token);
        localStorage.setItem("refreshtoken", resp.data.refresh);

        dispatch(onSignInSuccess(token));
        const payload = decodeJwt(token);
        if (payload.role) {
          dispatch(
            setUser({
              role: payload.role,
              name: payload.first_name,
            }),
          );
        }
        const redirectUrl = query.get("redirectUrl");

        navigate(redirectUrl ? redirectUrl : "/dashboard");

        return {
          status: "success",
          message: "",
        };
      }
    } catch (errors) {
      throw errors;
    }
  };

  //   const signUp = async (values) => {
  //     try {
  //       const resp = await apiSignUp(values);
  //       if (resp.data) {
  //         const { token } = resp.data;
  //         dispatch(onSignInSuccess(token));
  //         if (resp.data.user) {
  //           dispatch(
  //             setUser(
  //               resp.data.user || {
  //                 avatar: "",
  //                 userName: "Anonymous",
  //                 authority: ["USER"],
  //                 email: "",
  //               }
  //             )
  //           );
  //         }
  //         const redirectUrl = query.get(REDIRECT_URL_KEY);
  //         navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
  //         return {
  //           status: "success",
  //           message: "",
  //         };
  //       }
  //     } catch (errors) {
  //       return {
  //         status: "failed",
  //         message: errors?.response?.data?.message || errors.toString(),
  //       };
  //     }
  //   };

  const handleSignOut = () => {
    const initialStateinit: any = initialState;
    dispatch(onSignOutSuccess(initialStateinit));
    dispatch(setUser(initialStateinit));
    localStorage.clear();
  };

  const signOut: any = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn && !isTokenExpired,
    signIn,
    // signUp,
    signOut,
  };
}

export default useAuth;
