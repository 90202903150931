import React from "react";
import dayjs from "dayjs";
import useStyles from "../../Popup/style";
import { Typography, FormControl, Tooltip } from "@mui/material";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface DatePickerControllerProps {
  label: string;
  dataKey: string;
  isRequired?: boolean;
  defaultValue?: string;
  isDisabled?: boolean;
  disablePast?: boolean;
}

const DatePickerController: React.FC<DatePickerControllerProps> = ({
  label,
  dataKey,
  defaultValue,
  isRequired = false,
  isDisabled = false,
  disablePast = true,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const classes = useStyles();
  const isError = errors[dataKey];

  return (
    <div className={classes.spaceBetween}>
      <Typography className={classes.label}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <FormControl className={classes.formControl}>
        <Controller
          name={dataKey}
          control={control}
          defaultValue={dayjs(defaultValue)}
          render={({ field }) => (
            <Tooltip title={isError ? (errors[dataKey] as FieldError)?.message : ""} arrow>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="YYYY - MM - DD"
                  {...field}
                  onError={() => {}}
                  sx={{ borderColor: isError ? "main" : "" }}
                  disabled={isDisabled}
                  disablePast={disablePast}
                />
              </LocalizationProvider>
            </Tooltip>
          )}
        />
      </FormControl>
    </div>
  );
};

export default DatePickerController;
