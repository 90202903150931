import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  projectCard: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #AFBEC5",
    borderRadius: "15px",
    overflow: "hidden",
    width: "470px",
  },
  projectCardHead: {
    display: "flex",
    flexDirection: "row",
    padding: "15px",
    borderBottom: `1px solid ${theme.palette.decorative.white.shade2}`,
  },

  projectStatus: {},

  projectAvatarAndTItle: {
    display: "flex",
    flexDirection: "row",
  },
  projectAvatar: {
    verticalAlign: "top",
    marginRight: "15px",
  },
  avatar: {
    bgcolor: "red",
  },

  projectTitle: {},

  commonTag: {
    verticalAlign: "top",
    borderRadius: "5px !important",
  },
  projectNameStatus: {
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "9px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  status: {
    display: "flex",
    flexDirection: "row",
  },

  date: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-5px",
    gap: "5px",
  },

  bigLabelBox: {
    flex: 1,
    fontSize: "14px",
    marginBottom: "10px",
  },
  projectDateTeam: {
    display: "flex",
    justifyContent: "space-between",
  },
  dateWrap: {
    display: "flex",
    gap: "15px",
  },
  endDate: { display: "flex", flexDirection: "column" },

  fixedWidthChip: {
    width: "fit-content",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // display: "inline-block",
  },
  noWarp: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  noWarpf: {
    width: "260px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  badgeSuccess: {},

  startDateNEnd: {},
  projectDateText: {
    fontSize: "12px",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "end",
  },

  projectDateLabel: {
    color: theme.palette.decorative.white.shade3,
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "end",
  },

  box: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    verticalAlign: "top",
  },

  projectContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "5px",
    "& .MuiTypography-h4": {
      fontSize: "14px",
      marginBottom: "0px",
      fontWeight: "600",
    },
    "& .bigLabel": {
      fontSize: "14px",
      marginBottom: "10px",
      whiteSpace: "nowrap",
    },
    padding: "15px",
  },

  bigLabel: {
    color: theme.palette.decorative.white.shade3,
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    whiteSpace: "nowrap",
  },

  users: {
    display: "flex",
    flexDirection: "row",
  },

  otherMembers: {
    marginLeft: "15px",
    fontSize: "14px",
    color: theme.palette.decorative.blue.shade4,
  },

  searchPaginateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  teamMembersBox: {
    paddingBottom: "10px",
    display: "flex",
    alignItems: "center",
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
    color: "dodgerblue",
  },
  memberAvatarWarper: {
    display: "flex",
    flexDirection: "column",
  },

  memberAvatarImg: {
    position: "relative",
    "&:hover": {
      border: "2px solid #1976d2",
      borderRadius: "50%",
      zIndex: 1, // Ensures Avatar is on top when hovered
    },
    "&:hover + $memberAvatarImgTxt": {
      visibility: "visible",
    },
  },
  memberAvatarImgTxt: {
    position: "absolute",
    bottom: "82px",
    visibility: "hidden",
    color: theme.palette.decorative.white.shade3,
    padding: theme.spacing(1),
  },
  hoverText: {
    "&:hover + $memberAvatarImgTxt": {
      visibility: "visible",
    },
  },
}));
