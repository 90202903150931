import { useDispatch } from "react-redux";
import { IAppSlice, closeSnack, openSnack } from "store/common/appSlice";

export const useToggleSnack = () => {
  const dispatch = useDispatch();

  const show = ({ type, message }: { type: IAppSlice["type"]; message: string }) => {
    dispatch(openSnack({ type, message }));
  };
  const close = () => {
    dispatch(closeSnack());
  };
  return { show, close };
};
