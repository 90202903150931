import * as React from "react";
import Box from "@mui/material/Box";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import useStyles from "../../Popup/style";

interface BasicModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactNode;
  title: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "15px",
};

const BasicModal: React.FC<BasicModalProps> = ({ open, setOpen, children, title }) => {
  const handleClose = () => setOpen(false);
  const classes = useStyles();
  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box className={classes.modalHead}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }} gutterBottom>
              {title}
            </Typography>
            <HighlightOffIcon onClick={handleClose} />
          </Box>
          {children}
        </Box>
      </Modal>
    </div>
  );
};

export default BasicModal;
