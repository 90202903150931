import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import userImg from "../../../assets/images/user.png";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";

interface UserCardProps {
  data: {
    id: number;
    profile_picture: string;
    designation: string;
    team_name: string;
    location: string;
    name: string;
    mailid: string;
  };
}

const UserCard: React.FC<UserCardProps> = (props) => {
  const { data } = props;
  const { t } = useTranslation("userCard");
  const navigate = useNavigate();

  const classes = useStyles();
  const handleEdit = () => {
    navigate(`/employee/${data.id}`);
  };
  function TrimWithDot(data: string) {
    return data && data.length > 20 ? ` ${data.substring(0, 20)}...` : data;
  }
  return (
    <Box className={classes.userCard}>
      <Box className={classes.userCardHead}>
        <Box className={classes.userImg}>
          <Avatar sx={{ width: 50, height: 50, bgcolor: "#0084c3", borderRadius: "50%" }} variant="rounded">
            <img src={data.profile_picture ? data.profile_picture : userImg} alt="" style={{ width: "50px", height: "50px" }} />
          </Avatar>
        </Box>
        <Box className={classes.userTitle} onClick={handleEdit}>
          <Typography variant="h6">{data.name}</Typography>
          <Typography variant="body1">{data.designation}</Typography>
          <Typography variant="body2" className={classes.bodyText} style={{ color: "grey" }}>
            <br />
            {t("id")} <span className={classes.blackText}>{data.id}</span>
            <br />
            {t("department")} <span className={classes.blackText}>{TrimWithDot(data?.team_name)}</span>
            <br />
            {t("location")} <span className={classes.blackText}>{TrimWithDot(data?.location)}</span>
            <br />
            {t("mail")} <span className={classes.blackText}>{TrimWithDot(data?.mailid)}</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserCard;
