import * as yup from "yup";

const schema = yup.object().shape({
  email: yup.string().required("Username is required"),
  // email: yup.string().email("Invalid email").required("Username is required"),

  password: yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
});

export default schema;
