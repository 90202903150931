import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  HeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    color: "blue",
    width: "100%",
    marginRight: "20px",
  },
  addButton: {
    "& .MuiSvgIcon-root": {
      color: "dodgerblue",
    },
  },
  Text: {
    color: theme.palette.decorative.white.shade4,
    fontWeight: "600",
    fontSize: "26px",
    margin: "0 15px",
  },

  headerButtonText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
    color: "dodgerblue",
  },
  userCard: {
    border: `2px solid ${theme.palette.decorative.white.shade7}`,
    borderRadius: "8px",
    margin: "5px",
    width: "360px",
    height: "250px",
    background: theme.palette.decorative.white.main,
  },
  userImg: {
    height: "60px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    borderRadius: "5px",
    color: theme.palette.decorative.white.main,
    fontWeight: 500,
    letterSpacing: "2px",
  },
  bodyText: {
    fontFamily: "Poppins",
    color: "grey",
    fontSize: "16px !important",
    padding: "10px",
  },
  blackText: {
    fontFamily: "Poppins",
    color: "#000000",
    fontSize: "14px !important",
    padding: "10px",
  },
  userTitle: {
    marginTop: "0px",
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "10px",
  },
  userCardHead: {
    display: "flex",
    padding: "15px",
    marginRight: "0px",
  },
  users: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentArea: {
    padding: "15px",
    paddingRight: "60px",
    // gap: "15px",
    display: "flex",
    flexDirection: "column",
  },
  timelineContainer: {
    display: "flex",
    justifyContent: "center",
  },
  overlappingBox: {
    marginTop: "-40px",
    padding: "20px",
  },
  bodyCard: {
    display: "grid",
    gridTemplateColumns: "auto auto",
  },
  bodyItem: {
    marginBottom: "5px",
  },
  commonCardHead: {
    borderRadius: "8px",
    background: theme.palette.decorative.white.main,
    overflow: "hidden",
  },
  commonCard: {
    borderRadius: "18px",
    background: theme.palette.decorative.white.main,
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
  },
  gradientBackground: {
    background: "linear-gradient(180deg, #218DCC -32.1%, rgba(33, 141, 204, 0.085) 73.73%, rgba(33, 141, 204, 0) 100%)",
  },
  tabPane: {
    padding: "10px",
  },
  projectAvatar: {
    marginBlock: "27px",
    marginInline: "20px",
    alignItems: "center",
    display: "flex",
    marginTop: "15px",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  heades: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  navBorder: {
    borderBottom: `.5px solid ${theme.palette.decorative.white.shade2}`,
  },
  noWarp: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  navLink: {
    fontSize: "18px",
    padding: "1% 5%",
    fontWeight: "500px",
    color: theme.palette.decorative.black.shade1,
    border: "0",
    position: "relative",
    background: "none",
    "&.active": {
      color: theme.palette.decorative.blue.main,
      "&::before": {
        width: "100%",
        content: '""',
        position: "absolute",
        left: "0px",
        right: "0px",
        height: "4px",
        background: theme.palette.decorative.blue.shade1,
        bottom: "0px",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
      },
    },
  },
}));
