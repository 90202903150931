import React, { useState } from "react";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { Typography, TextField, Tooltip } from "@mui/material";
import useStyles from "../../Popup/style";
import { Box } from "@mui/system";

interface InputTimeNumberControllerProps {
  name: string;
  label: string;
  placeHolder: string;
  children?: React.ReactNode;
  type?: string;
  isRequired?: boolean;
  defaultValue?: string;
}

const InputTimeNumberController: React.FC<InputTimeNumberControllerProps> = ({
  name,
  label,
  placeHolder,
  type,
  children,
  isRequired = false,
  defaultValue,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();
  const isError = errors[name];
  const [inputValue, setInputValue] = useState(defaultValue || "");

  const handleInputChange = (event: any, onChange: any) => {
    const { value } = event.target;

    // Regular expression to allow numbers or float numbers
    const regex = /^(2[0-3]|[01]?[0-9])(\.[0-5]?[0-9]?)?$/;
    if (value === "24" || regex.test(value) || value === "") {
      setInputValue(value);
      onChange(value);
    }
  };

  return (
    <Box style={{ display: "flex" }}>
      <Typography className={classes.picker}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Box style={{ flex: 1 }}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <Tooltip title={isError ? (errors[name] as FieldError)?.message : ""} arrow>
              <TextField
                {...field}
                placeholder={placeHolder}
                InputProps={children ? { startAdornment: <>{children}</> } : undefined}
                type={type}
                variant="outlined"
                value={inputValue}
                size="small"
                fullWidth
                onChange={(e) => {
                  handleInputChange(e, field.onChange);
                }}
                error={Boolean(isError)}
                sx={{ borderColor: isError ? "main" : "" }}
              />
            </Tooltip>
          )}
        />
      </Box>
    </Box>
  );
};

export default InputTimeNumberController;
