import { Grid } from "@mui/material";
import React from "react";
import { LeftPane } from "./LeftPane";
import { RightPane } from "./RightPane";

const Login = () => {
  return (
    <Grid container>
      <LeftPane />
      <RightPane />
    </Grid>
  );
};

export default Login;
