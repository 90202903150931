import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import login from "./locale/en/loginScreen.json";
import dashboard from "./locale/en/hrDashboard.json";
import userCard from "./locale/en/userCard.json";
import userDrawer from "./locale/en/userDrawer.json";
import userDetail from "./locale/en/userDetail.json";
import projectDrawer from "./locale/en/projectDrawer.json";
import common from "./locale/en/common.json";
import clientHeader from "./locale/en/clientHeader.json";
import profileHeader from "./locale/en/profileHeader.json";
import pmDashboard from "./locale/en/pmDashboard.json";
import timeSheet from "./locale/en/timeSheet.json";
import employeeDetailHeader from "./locale/en/employeeDetailHeader.json";
import clientDetail from "./locale/en/clientDetail.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      login,
      dashboard,
      userCard,
      userDrawer,
      userDetail,
      projectDrawer,
      common,
      clientHeader,
      pmDashboard,
      timeSheet,
      profileHeader,
      employeeDetailHeader,
      clientDetail,
    },
  },
  lng: "en", // Set the initial language of the App
});
