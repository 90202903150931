import * as yup from "yup";

const schema = yup.object().shape({
  project: yup.string().required("Project Name is required"),
  date: yup.date().required("Date is required"),
  productive_hr: yup.string().required("Productive hours is required"),
  // .matches(/^[1-9]\d*$/, "Estimate hours must be a valid non-zero number")
  // .max(5, "Estimate hours must not exceed 5 digits"),
  billable_by_emp: yup.string().required("Billable hours is required"),
  // .matches(/^[1-9]\d*$/, "Actual hours must be a valid non-zero number")
  // .max(5, "Actual hours must not exceed 5 digits"),
  task_title: yup.string().required("Task is required"),
});

export default schema;
