import { Get, Post, Patch } from "./axios";

const baseUrl = "/timesheet";
const TaskListURL = `${baseUrl}/task_list_view/`;
const TaskListCreateURL = `${baseUrl}/time-entries/create/`;
const TimeSheetByProjectIdURL = `${baseUrl}/project-detail/`;
const UpdateTimeSheetPmURL = `${baseUrl}/update-time-sheet-pm/`;
const EditTimeEntryUserURL = `${baseUrl}/time-entries-update/`;

const TaskListAPI = (payload: any) => {
  return Get({ url: TaskListURL, request: payload });
};

const TaskListCreateAPI = (payload: any) => {
  return Post({ url: TaskListCreateURL, request: payload });
};

const TimeSheetByProjectIdAPI = (payload: any) => {
  return Get({ url: `${TimeSheetByProjectIdURL}${payload}`, request: payload });
};

const UpdateTimeSheetPmAPI = (payload: any) => {
  return Patch({ url: `${UpdateTimeSheetPmURL}${payload.id}/`, request: { billable_by_pm: payload.billable_by_pm } });
};

const EditTimeEntryUserAPI = (payload: any) => {
  return Patch({
    url: `${EditTimeEntryUserURL}${payload.id}/`,
    request: {
      billable_by_emp: payload.billable_by_emp,
      productive_hr: payload.productive_hr,
      task_title: payload.task_title,
    },
  });
};

export { TaskListAPI, TaskListCreateAPI, TimeSheetByProjectIdAPI, UpdateTimeSheetPmAPI, EditTimeEntryUserAPI };
