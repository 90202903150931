const project = {
  status: [
    { id: 1, name: "Processing" },
    { id: 2, name: "Ongoing" },
    { id: 3, name: "Completed" },
    { id: 4, name: "Paused" },
    { id: 5, name: "Dropped" },
    { id: 6, name: "Closed" },
  ],
  type: [
    { id: 1, name: "Fixed" },
    { id: 2, name: "Hourly" },
  ],
};

interface NameIdInterface {
  id: number;
  name: string;
}

const getConstantById = (value: keyof typeof project, id: number) => {
  const data = project[value].filter((e: NameIdInterface) => e.id === id);

  return data?.[0] ? data[0] : { id: 1, name: "Processing" };
};
const getConstantByName = (value: keyof typeof project, name?: string) => {
  const data = project[value].filter((e: NameIdInterface) => e.name === name);
  return data?.[0] ? data[0] : { id: 1, name: "Processing" };
};

const getConstant = (value: keyof typeof project) => {
  return project[value];
};

export { getConstant, getConstantById, getConstantByName };
