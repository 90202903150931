import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import userImg from "../../../assets/images/user.png";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useSelector } from "react-redux";
import { clientSelector } from "store/client/index";
import { getClientByIdAPI } from "serviceHandlers/clientServices";
import ActiveProject from "components/Project/active";
import ArchivedProject from "components/Project/archive";

interface ClientCardProps {
  id: number;
  name: string;
  profile_picture: string;
  country: string;
  currency: string;
  address: string;
  website: string;
  number: string;
  skype: string;
  email: string;
  others: string;
  bdm: number;
}

const EmployeeDetail: React.FC = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [data, setData] = useState<ClientCardProps | null>(null);
  const [tab, setTab] = useState("about");
  const classes = useStyles();
  const { t } = useTranslation(["clientDetail", "projectDrawer"]);
  const { isUpdated } = useSelector(clientSelector);

  const fetchData = async () => {
    try {
      const result = await getClientByIdAPI(clientId);
      setData(result.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [clientId]);

  useEffect(() => {
    if (isUpdated) {
      fetchData();
    }
  }, [isUpdated]);

  let componentToShow;

  const About = () => {
    return (
      <Box className={classes.bodyCard}>
        {(
          [
            ["name", "name"],
            ["country", "country"],
            ["currency", "currency"],
            ["address", "address"],
            ["website", "website"],
            ["number", "number"],
            ["skype", "skype"],
            ["email", "email"],
            ["others", "others"],
          ] as Array<[string, keyof ClientCardProps]>
        )?.map(([labelKey, dataKey]) => (
          <Box key={labelKey} className={classes.bodyItem}>
            <Typography variant="body2" className={classes.bodyText}>
              {t(labelKey)}
            </Typography>
            <Typography variant="body2" className={classes.blackText}>
              {data?.[dataKey] || "--"}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };

  switch (tab) {
    case "active":
      componentToShow = <ActiveProject />;
      break;
    case "archived":
      componentToShow = <ArchivedProject />;
      break;
    default:
      componentToShow = <About />;
  }

  return (
    <>
      {data && (
        <Box className={classes.contentArea}>
          <Box className={`${classes.commonCardHead} ${classes.gradientBackground}`}>
            <Box className={classes.tabPane} id="tile-1">
              <Box className={classes.projectAvatar}>
                <Box className={classes.heades}>
                  <Avatar sx={{ width: 100, height: 100, bgcolor: "#0084c3", borderRadius: "50%", border: "4px solid white" }} variant="rounded">
                    <img src={data?.profile_picture || userImg} alt="" style={{ width: "100px", height: "100px" }} />
                  </Avatar>
                  <Box>
                    <Typography variant="h5">{`${data?.name}`}</Typography>
                    {/* <Typography variant="body1" style={{ marginBottom: "5px", fontSize: "14px" }}>
                      {data?.designation}
                    </Typography> */}
                    <Typography variant="body1" style={{ marginBottom: "5px", fontSize: "14px" }}>
                      <LocationOnIcon style={{ verticalAlign: "middle", marginRight: "5px", fontSize: "16px", marginBottom: "5px" }} />
                      {data?.country}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={`${classes.overlappingBox}`}>
            <Box className={classes.commonCard}>
              <Box className={classes.tabPane} id="tile-1">
                <nav>
                  <Box id="nav-tab" role="tablist" className={classes.navBorder}>
                    <button
                      className={`${classes.navLink} ${tab === "about" && "active"}`}
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={() => {
                        setTab("about");
                      }}
                    >
                      {t("about")}
                    </button>
                    <button
                      className={`${classes.navLink} ${tab === "active" && "active"}`}
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                      onClick={() => {
                        setTab("active");
                      }}
                    >
                      {t("projectDrawer:activepro")}
                    </button>
                    <button
                      className={`${classes.navLink} ${tab === "archived" && "active"}`}
                      id="nav-profile-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-profile"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                      onClick={() => {
                        setTab("archived");
                      }}
                    >
                      {t("projectDrawer:archivedpro")}
                    </button>
                  </Box>
                </nav>
                {componentToShow}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EmployeeDetail;
