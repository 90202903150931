import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface IAppSlice {
  isCreated: boolean;
  isUpdated: boolean;
}
export const initialState: IAppSlice = {
  isCreated: false,
  isUpdated: false,
};

export const createdUpdatedSlice = createSlice({
  name: "created/updated",
  initialState,
  reducers: {
    IsUserCreated: (state, action) => {
      state.isCreated = action.payload;
    },
    IsUserUpdated: (state, action) => {
      state.isUpdated = action.payload;
    },
  },
});

export const { IsUserCreated, IsUserUpdated } = createdUpdatedSlice.actions;
export const createdUpdatedSelector = (state: RootState) => state.createdUpdatedReducer;
export default createdUpdatedSlice.reducer;
