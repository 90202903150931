import React from "react";
import { Button, Typography, Tooltip, Avatar } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, styled } from "@mui/system";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import useStyles from "../../Popup/style";
import { useState } from "react";
import userImg from "../../../assets/images/user.png";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface FileUploadButtonProps {
  name: string;
  defaultValue?: string;
  label: string;
  type: string;
  onUploadStatusChange: (status: string | null) => void;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ defaultValue, name, label, type, onUploadStatusChange }) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();
  const isError = errors[name];
  const [uploadStatus, setUploadStatus] = useState<string | null>(null);
  const [profilePic, setProfilePic] = useState("");

  /**
   *
   * @param event
   * @returns
   */
  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];

    //   if (file) {
    //     return await convertFileToBase64(file);
    //   }
    // };
    if (file) {
      try {
        const base64String = await convertFileToBase64(file);
        setUploadStatus("File uploaded successfully!");
        onUploadStatusChange("Image uploaded successfully!");
        setProfilePic(base64String);
        return base64String;
      } catch (error) {
        console.error("Error converting file to base64:", error);
        setUploadStatus("Failed to upload file. Please try again.");
        onUploadStatusChange("Failed to upload image. Please try again.");
      }
    }
  };

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const result = reader.result as string;
        resolve(result.split(",")[1]); // Extract base64 string excluding the data URL prefix
      };

      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    });
  };

  const createBase64 = async (event: any, onChange: any) => {
    const value = await handleFileChange(event);
    onChange(value);
  };

  return (
    <>
      <Typography className={classes.label}></Typography>
      <Box className={classes.AvatarWrapper} style={{ border: `2px solid ${isError ? "red" : "white"}` }}>
        <Tooltip title={isError ? (errors[name] as FieldError)?.message : ""} arrow>
          <Avatar sx={{ width: 100, height: 100, bgcolor: "#0084c3", borderRadius: "50%", border: "4px solid white" }} variant="rounded">
            <img
              src={(profilePic && `data:image/jpeg;base64,${profilePic}`) || defaultValue || userImg}
              alt=""
              style={{ width: "100px", height: "100px" }}
            />
          </Avatar>
        </Tooltip>
      </Box>
      <Button component="label" variant="contained" size="medium" fullWidth startIcon={<CloudUploadIcon />}>
        {label}
        <Controller
          name={name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <VisuallyHiddenInput
              {...field}
              value={undefined}
              type={type}
              onChange={(e) => {
                createBase64(e, field.onChange);
              }}
              accept="image/*"
              style={{ display: "none" }} // Hide the input visually
              // error={Boolean(isError)}
              sx={{ borderColor: isError ? "main" : "" }}
            />
          )}
        />
      </Button>
    </>
  );
};

export default FileUploadButton;
