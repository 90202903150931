import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export const initialState = {
  role: "",
  isProfileUpdated: false,
};

export const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (_, action) => action.payload,
    userLoggedOut: () => initialState,
    IsProfileUpdated: (state, action) => {
      state.isProfileUpdated = action.payload;
    },
  },
});

export const { setUser, IsProfileUpdated } = userSlice.actions;
export const authSelector = (state: RootState) => state.auth.user;

export default userSlice.reducer;
