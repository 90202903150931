import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../../utils/hooks/useAuth";
import { AppLayout } from "components";

const ProtectedRoute: React.FC = () => {
  const { authenticated } = useAuth();
  if (!authenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default ProtectedRoute;
