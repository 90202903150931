import * as yup from "yup";

const schema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  // email: yup.string().email().required(),
  // employee_code: yup.string().required(),
  address: yup.string().required(),
  profile_picture: yup.string(),
  // designation: yup.string(),
  // team_name: yup.string(),
  // location: yup.string(),
  // name: yup.string(),
  // assigned_to: yup.string(),
  // date_of_joining: yup.string(),
  // role: yup.string(),
  skills: yup.array().required().min(1),
});

export default schema;
