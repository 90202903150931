import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  homeSection: {
    position: "relative",
    // height: "100vh",
    left: "260px",
    width: "calc(100% - 260px)",
    transition: "all 0.5s ease",
    paddingLeft: "15px",
    "&.close": {
      left: "110px",
      width: "calc(100% - 110px)",
    },
  },
  homeContent: {
    height: "60px",
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    color: theme.palette.decorative.white.shade4,
    fontSize: "35px",
  },
}));
