import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/routes/protectedRoutes";
import PublicRoute from "./components/routes/publicRoute";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { useSnackBar } from "components/common/useSnack";
import { dataConfig, errorConfig } from "./components/common/config/config";
import Body from "./components/Body";
import { usePermissions } from "utils/hooks/usePermissions";
import TempMember from "components/TempMember/approval";

interface MyData {
  dashBoardHeaderArea?: React.ReactNode;
  projectHeaderArea?: React.ReactNode;
  employeeHeaderArea?: React.ReactNode;
  clientHeaderArea?: React.ReactNode;
  dashBoardContentArea?: React.ReactNode;
  projectContentArea?: React.ReactNode;
  employeeContentArea?: React.ReactNode;
  clientContentArea?: React.ReactNode;
  projectDetailContentArea?: React.ReactNode;
  timeSheetHeaderArea?: React.ReactNode;
  timeSheetContentArea?: React.ReactNode;
  temporaryContentArea?: React.ReactNode;
  temporaryHeaderArea?: React.ReactNode;
  employeeDetailHeaderArea?: React.ReactNode;
  employeeDetailContentArea?: React.ReactNode;
  profileContentArea?: React.ReactNode;
  timeSheetApprovalHeaderArea?: React.ReactNode;
  timeSheetApprovalContentArea?: React.ReactNode;
  profileHeaderArea?: React.ReactNode;
  clientDetailHeaderArea?: React.ReactNode;
  clientDetailContentArea?: React.ReactNode;
  myTemporaryMemberHeaderArea?: React.ReactNode;
  myTemporaryMemberContentArea?: React.ReactNode;
  temporaryMemberRequestedHeaderArea?: React.ReactNode;
  temporaryMemberRequestedContentArea?: React.ReactNode;
}

function App() {
  const { snackComponent } = useSnackBar();
  const myData: MyData = dataConfig;
  const { role }: { role: string } = usePermissions();
  const myDataItem = isValidRole(role) ? myData[role] : errorConfig;

  const {
    dashBoardHeaderArea,
    projectHeaderArea,
    employeeHeaderArea,
    clientHeaderArea,
    temporaryHeaderArea,
    dashBoardContentArea,
    projectContentArea,
    employeeContentArea,
    clientContentArea,
    projectDetailContentArea,
    timeSheetHeaderArea,
    timeSheetContentArea,
    temporaryContentArea,
    employeeDetailHeaderArea,
    employeeDetailContentArea,
    profileContentArea,
    timeSheetApprovalHeaderArea,
    timeSheetApprovalContentArea,
    profileHeaderArea,
    clientDetailHeaderArea,
    clientDetailContentArea,
    temporaryMemberRequestedHeaderArea,
    temporaryMemberRequestedContentArea,
    myTemporaryMemberHeaderArea,
    myTemporaryMemberContentArea,
  } = myDataItem as MyData;

  const getBodyComponent = (headerComponent: React.ReactNode, contentComponent: React.ReactNode) => (
    <Body headerComponent={headerComponent} contentComponent={contentComponent} />
  );

  const createRoute = (path: string, headerComponent: React.ReactNode, contentComponent: React.ReactNode) => (
    <Route path={path} element={getBodyComponent(headerComponent, contentComponent)} />
  );

  function isValidRole(role: string): role is keyof MyData {
    return role in myData;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<ProtectedRoute />}>
              {createRoute("/", dashBoardHeaderArea, dashBoardContentArea)}
              {createRoute("/dashboard", dashBoardHeaderArea, dashBoardContentArea)}
              {createRoute("/employees", employeeHeaderArea, employeeContentArea)}
              {createRoute("/profile", profileHeaderArea, profileContentArea)}
              {createRoute("/list-projects", projectHeaderArea, projectContentArea)}
              {createRoute("/list-employees", employeeHeaderArea, employeeContentArea)}
              {createRoute("/list-clients", clientHeaderArea, clientContentArea)}
              {createRoute("/employee/:employeeId", employeeDetailHeaderArea, employeeDetailContentArea)}
              {createRoute("/project/:projectId", projectHeaderArea, projectDetailContentArea)}
              {createRoute("/project/:projectId/time-sheet-approval", timeSheetApprovalHeaderArea, timeSheetApprovalContentArea)}
              {createRoute("/project/:projectId/temporary-member", temporaryHeaderArea, temporaryContentArea)}
              {createRoute("/time-sheet", timeSheetHeaderArea, timeSheetContentArea)}
              {createRoute("/time-sheet-approval", timeSheetApprovalHeaderArea, timeSheetApprovalContentArea)}
              {createRoute("/client/:clientId", clientDetailHeaderArea, clientDetailContentArea)}
              {createRoute("/temporary-members", myTemporaryMemberHeaderArea, myTemporaryMemberContentArea)}
              {createRoute("/temporary-members/requested", temporaryMemberRequestedHeaderArea, temporaryMemberRequestedContentArea)}
            </Route>
            <Route path="/" element={<PublicRoute />}>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route path="*" element={<h1>404</h1>} />
          </Routes>
          {snackComponent()}
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
