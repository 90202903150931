import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
  name: "dashboard/sidebar",
  initialState: {
    dashboardClosed: true,
  },
  reducers: {
    onDashboardClose: (state, action) => {
      state.dashboardClosed = action.payload;
    },
  },
});

export const { onDashboardClose } = sidebarSlice.actions;

export default sidebarSlice.reducer;
