import React from "react";
import { Typography, Box, Tooltip } from "@mui/material";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import useStyles from "../../Popup/style";
import { MuiChipsInput } from "mui-chips-input";
interface ChipInputProps {
  label: string;
  dataKey: string;
  defaultSkills: string[];
  defaultValue?: string | any;
  isRequired?: boolean;
}

const ChipInput: React.FC<ChipInputProps> = ({ defaultValue, label, dataKey, isRequired = false }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const classes = useStyles();
  const isError = errors[dataKey];
  return (
    <Box className={classes.spaceBetween}>
      <Typography className={classes.label}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Controller
        name={dataKey}
        control={control}
        defaultValue={defaultValue ? defaultValue.split(",").map((item: string) => item.trim()) : []}
        render={({ field, fieldState }) => (
          <Tooltip title={isError ? (errors[dataKey] as FieldError)?.message : ""} arrow>
            <MuiChipsInput
              {...field}
              hideClearAll
              error={fieldState.invalid}
              className={classes.fullWidthChipsInput}
              sx={{ borderColor: isError ? "main" : "" }}
            />
          </Tooltip>
        )}
      />
    </Box>
  );
};

export default ChipInput;
