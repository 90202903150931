import { combineReducers, Reducer, AnyAction } from "redux";
import auth from "./auth";
import clientReducer from "./client";
import dashboard from "./dashboard";
import snackReducer from "./common/appSlice";
import createdUpdatedReducer from "./common/createdUpdatedSlice";

interface AsyncReducers {
  [key: string]: Reducer<any, AnyAction>;
}

const rootReducer = (asyncReducers: AsyncReducers) => {
  const combinedReducer = combineReducers({
    auth,
    dashboard,
    snackReducer,
    createdUpdatedReducer,
    clientReducer,
    ...asyncReducers,
  });

  return combinedReducer;
};

export default rootReducer;
