import React from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToggleSnack } from "components/common/useToggleSnack";
import InputController from "components/common/components/input";
import logo from "../../assets/images/login-logo.png";
import design from "../../assets/images/login-dec.svg";
import userSvg from "../../assets/images/login-username-icon.svg";
import lockSvg from "../../assets/images/login-password-icon.svg";
import useAuth from "../../utils/hooks/useAuth";
import schema from "./schema";
import useStyles from "./style";

interface IFormInput {
  email: string;
  password: string;
}

export const LeftPane = () => {
  const { show } = useToggleSnack();
  const { signIn } = useAuth();
  const { t } = useTranslation(["login", "common"]);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });
  const { handleSubmit } = methods;
  const onSubmit = async (data: IFormInput) => {
    try {
      const response = await signIn(data);
      if (response?.status === "success") {
        show({ type: "success", message: "Login Success!!!!" });
      }
    } catch (error) {
      show({ type: "error", message: "Invalid login !!!" });
      console.log("error", error);
    }
  };

  const classes = useStyles();
  return (
    <FormProvider {...methods}>
      <Grid item xs={6} md={6} className={classes.root}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={`container ${classes.main}`}>
          <Typography variant="h2" className={classes.welcomeText}>
            {t("welcome")}
          </Typography>
          <Typography variant="body1" className={classes.bodyText}>
            {t("introtext")}
          </Typography>
          <br />
          <Box className={classes.mainBox}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className={classes.spaceBetween}>
                <InputController name="email" label="Username" placeHolder="Username">
                  <InputAdornment position="start">
                    <img src={userSvg} alt="user" className={classes.icon} />
                  </InputAdornment>
                </InputController>
              </Box>
              <Box>
                <InputController name="password" label="Password" type="password" placeHolder="Password">
                  <InputAdornment position="start">
                    <img src={lockSvg} alt="lock" className={classes.icon} />
                  </InputAdornment>
                </InputController>
              </Box>

              <Box>
                <Button type="submit" variant="contained" size="large" className={classes.button}>
                  {t("common:submit")}
                </Button>
              </Box>
            </form>
          </Box>
        </div>
        <img src={design} alt="" className={classes.design} />
      </Grid>
    </FormProvider>
  );
};
