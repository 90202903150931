import React from "react";
import { Drawer, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import useStyles from "./style";

const AddPopup: React.FC<{
  children: React.ReactNode;
  heading: string;
  open: boolean;
  onClose: () => void;
}> = ({ heading, children, open, onClose }) => {
  const classes = useStyles();
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box className={classes.drawerContent}>
        <Typography className={classes.drawerHeader}>
          {heading}
          <CancelIcon className={classes.cancelIcon} onClick={onClose} />
        </Typography>
        {children}
      </Box>
    </Drawer>
  );
};

export default AddPopup;
