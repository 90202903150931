import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddProject from "../Popup/Project/addProject";
import useStyles from "./style";
import AddPopup from "../Popup";
import { useTranslation } from "react-i18next";
import { usePermissions } from "utils/hooks/usePermissions";
import { Roles } from "utils/hooks/usePermissions";

const HeaderArea: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { role }: { role: string } = usePermissions();

  const { t } = useTranslation(["projectDrawer", "common"]);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleAddNewClick = () => {
    setIsDrawerOpen(true);
  };
  const classes = useStyles();
  return (
    <>
      <div className={classes.HeaderContent}>
        <Box component="span" className={classes.Text}>
          {t("projectDrawer:text")}
        </Box>
        {role === Roles.BDM && (
          <Box>
            <IconButton className={classes.addButton} onClick={handleAddNewClick}>
              <AddCircleOutlineIcon />
              <Typography variant="body1" className={classes.bodyText}>
                {t("common:addnew")}
              </Typography>
            </IconButton>
          </Box>
        )}
      </div>
      <AddPopup heading={"Add New Project"} open={isDrawerOpen} onClose={handleCloseDrawer}>
        <AddProject onClose={handleCloseDrawer} />
      </AddPopup>
    </>
  );
};

export default HeaderArea;
