import { useSelector } from "react-redux";

export enum Roles {
  BDM = "BDM",
  PM = "PM",
  TeamMember = "TeamMember",
  HR = "HR",
}
export const usePermissions = () => {
  const { role } = useSelector((state: any) => state.auth.user);

  return { role };
};
