import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import userImg from "../../../assets/images/user.png";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ViewProfileByIdAPI } from "../../../serviceHandlers/userProfileServices";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { useSelector } from "react-redux";
import { IsUserUpdated, createdUpdatedSelector } from "store/common/createdUpdatedSlice";
import { useDispatch } from "react-redux";

interface UserCardProps {
  id: number;
  first_name: string;
  last_name: string;
  profile_picture: string;
  designation: string;
  team_name: string;
  location: string;
  name: string;
  email: string;
  assigned_to: string;
  employee_code: number;
  date_of_joining: number;
}

const EmployeeDetail: React.FC = () => {
  const { employeeId } = useParams<{ employeeId: string }>();
  const [data, setData] = useState<UserCardProps | null>(null);
  const { t } = useTranslation("userDetail");
  const [tab, setTab] = useState("about");
  const classes = useStyles();

  const dispatch = useDispatch();
  const { isUpdated } = useSelector(createdUpdatedSelector);

  useEffect(() => {
    dispatch(IsUserUpdated(false));
    const fetchData = async () => {
      try {
        const result = await ViewProfileByIdAPI(employeeId);
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [employeeId, isUpdated]);

  // const calculateWorkAnniversary = (joiningDate: number, anniversaryYear: number) => {
  //   const today = new Date();
  //   const anniversaryDate = new Date(joiningDate);
  //   anniversaryDate.setFullYear(anniversaryDate.getFullYear() + anniversaryYear);

  //   return anniversaryDate <= today ? anniversaryDate : null;

  const calculateWorkAnniversary = (joiningDate: number, anniversaryYear: number) => {
    const anniversaryDate = new Date(joiningDate);
    anniversaryDate.setFullYear(anniversaryDate.getFullYear() + anniversaryYear);
    return anniversaryDate;
  };
  return (
    <>
      {data && (
        <Box className={classes.contentArea}>
          <Box className={`${classes.commonCardHead} ${classes.gradientBackground}`}>
            <Box className={classes.tabPane} id="tile-1">
              <Box className={classes.projectAvatar}>
                <Box className={classes.heades}>
                  <Avatar sx={{ width: 100, height: 100, bgcolor: "#0084c3", borderRadius: "50%", border: "4px solid white" }} variant="rounded">
                    <img src={data?.profile_picture || userImg} alt="" style={{ width: "100px", height: "100px" }} />
                  </Avatar>
                  <Box>
                    <Typography variant="h5">{`${data?.first_name} ${data?.last_name}`}</Typography>
                    <Typography variant="body1" style={{ marginBottom: "5px", fontSize: "14px" }}>
                      {data?.designation}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: "5px", fontSize: "14px" }}>
                      <LocationOnIcon style={{ verticalAlign: "middle", marginRight: "5px", fontSize: "16px", marginBottom: "5px" }} />
                      {data?.location}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={`${classes.overlappingBox}`}>
            <Box className={classes.commonCard}>
              <Box className={classes.tabPane} id="tile-1">
                <nav>
                  <Box id="nav-tab" role="tablist" className={classes.navBorder}>
                    <button
                      className={`${classes.navLink} ${tab === "about" && "active"}`}
                      type="button"
                      onClick={() => {
                        setTab("about");
                      }}
                    >
                      {t("abt")}
                    </button>
                    {/* <button
                  className={`${classes.navLink} ${tab === "timeline" && "active"}`}
                  type="button"
                  onClick={() => {
                    setTab("timeline");
                  }}
                >
                  {t("tline")}
                </button> */}
                  </Box>
                </nav>
                {tab === "about" ? (
                  <Box className={classes.bodyCard}>
                    {(
                      [
                        ["department", "team_name"],
                        ["location", "location"],
                        ["mail", "email"],
                        ["empcode", "employee_code"],
                        ["assignee", "assigned_to"],
                        ["doj", "date_of_joining"],
                      ] as Array<[string, keyof UserCardProps]>
                    )?.map(([labelKey, dataKey]) => (
                      <Box key={labelKey} className={classes.bodyItem}>
                        <Typography variant="body2" className={classes.bodyText}>
                          {t(labelKey)}
                        </Typography>
                        <Typography variant="body2" className={classes.blackText}>
                          {data?.[dataKey] || "--"}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  // <div className={classes.timelineContainer}>
                  //   <Timeline>
                  //     {[1, 2, 3]?.map((anniversaryYear) => {
                  //       const anniversaryDate = calculateWorkAnniversary(data?.date_of_joining ?? 0, anniversaryYear);
                  //       return (
                  //         anniversaryDate && (
                  //           <TimelineItem key={anniversaryYear}>
                  //             <TimelineSeparator>
                  //               <EmojiEventsIcon />
                  //               {anniversaryYear < 3 && <TimelineConnector />}
                  //             </TimelineSeparator>
                  //             <TimelineContent>{`${t("workAnniversary")} ${anniversaryYear}: ${anniversaryDate.toLocaleDateString()}`}</TimelineContent>
                  //           </TimelineItem>
                  //         )
                  //       );
                  //     })}
                  //   </Timeline>
                  // </div>
                  // <div className={classes.timelineContainer}>
                  //   <Timeline>
                  //     <TimelineItem>
                  //       <TimelineSeparator>
                  //         <EmojiEventsIcon color="primary" />
                  //         <TimelineConnector />
                  //       </TimelineSeparator>
                  //       <TimelineContent>Third Work Anniversary</TimelineContent>
                  //     </TimelineItem>
                  //     <TimelineItem>
                  //       <TimelineSeparator>
                  //         <EmojiEventsIcon />
                  //         <TimelineConnector />
                  //       </TimelineSeparator>
                  //       <TimelineContent>Second Work Anniversary</TimelineContent>
                  //     </TimelineItem>
                  //     <TimelineItem>
                  //       <TimelineSeparator>
                  //         <EmojiEventsIcon />
                  //       </TimelineSeparator>
                  //       <TimelineContent>First Work Anniversary</TimelineContent>
                  //     </TimelineItem>
                  //   </Timeline>
                  // </div>
                  <div className={classes.timelineContainer}>
                    <Timeline>
                      {[1, 2, 3]?.map((anniversaryYear) => (
                        <TimelineItem key={anniversaryYear}>
                          <TimelineSeparator>
                            <EmojiEventsIcon />
                            {anniversaryYear < 3 && <TimelineConnector />}
                          </TimelineSeparator>
                          <TimelineContent>
                            {`${t("workAnniversary")} ${anniversaryYear}: ${calculateWorkAnniversary(
                              data?.date_of_joining ?? 0,
                              anniversaryYear,
                            ).toLocaleDateString()}`}
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </Timeline>
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default EmployeeDetail;
