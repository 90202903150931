import { AlertColor } from "@mui/material";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface IAppSlice {
  type?: AlertColor;
  visibility: boolean;
  message: null | string;
}
export const initialState: IAppSlice = {
  type: undefined,
  visibility: false,
  message: null,
};

export const snackSlice = createSlice({
  name: "common/snack",
  initialState,
  reducers: {
    openSnack: (state, action: PayloadAction<{ message: string; type: typeof initialState.type }>) => {
      state.type = action.payload.type;
      state.visibility = true;
      state.message = action.payload.message;
    },
    closeSnack: (state) => {
      state.type = undefined;
      state.visibility = false;
      state.message = null;
    },
  },
});
export const { openSnack, closeSnack } = snackSlice.actions;
export const snackStateSelector = (state: RootState) => state.snackReducer;
export default snackSlice.reducer;
