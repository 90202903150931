import React, { useEffect, useState } from "react";
import { Box, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { addDays, startOfWeek, format } from "date-fns";
import useStyles from "./style";
import { useTranslation } from "react-i18next";
import { TaskListAPI } from "serviceHandlers/timeSheetServices";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { createdUpdatedSelector } from "store/common/createdUpdatedSlice";
import AddPopup from "../Popup";
import EditHour from "../Popup/TimeSheet/editTimeSheet";

interface TimeEntry {
  id?: string;
  project_name?: string;
  project?: string;
  task_title?: string;
  productive_hr: string;
  billable_by_emp: string;
  date: string;
  action?: string[];
}

interface RowData {
  id?: string;
  project_name?: string;
  task_title?: string;
  productive_hr: string;
  billable_by_emp: string;
  date: string;
  action?: string[];
}

const TimeSheetContentArea: React.FC = () => {
  const classes = useStyles();

  const { t } = useTranslation(["pmDashboard", "common"]);

  const [timeEntries, setTimeEntries] = useState<TimeEntry[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [newProjectName, setNewProjectName] = useState("");
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(new Date()));
  const [clearData, setClearData] = useState(false);
  const [rowDatas, setRowDatas] = useState<RowData | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };
  const { isCreated, isUpdated } = useSelector(createdUpdatedSelector);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);
  const handleAddNewEntry = (newEntry: TimeEntry) => {
    setTimeEntries([...timeEntries, newEntry]);
  };
  // const handleAddProject = () => {
  //   const newProject: TimeEntry = {
  //     ...initialProject,
  //     projectName: newProjectName,
  //   };

  //   setTimeEntries([...timeEntries, newProject]);
  //   setNewProjectName("");
  //   handleCloseDialog();
  // };

  // useEffect(() => {
  //   setClearData(false); // Set clearData to true
  // }, [clearData]);
  const fetchTimeSheetData = async () => {
    try {
      const response = await TaskListAPI(newProjectName);
      if (response && response.data) {
        setTimeEntries(response.data);
      }
    } catch (error) {
      // Handle error, e.g., log it or show an error message
      console.error("Error fetching time sheet data:", error);
    }
  };

  useEffect(() => {
    if (isCreated || isUpdated) {
      fetchTimeSheetData();
    }
  }, [isCreated, isUpdated]);

  useEffect(() => {
    fetchTimeSheetData();
  }, []);

  const columns = [
    { id: "project", label: "Project", render: (row: RowData) => row.project_name },
    { id: "task_title", label: "Task Name", render: (row: RowData) => row.task_title },
    { id: "productive_hr", label: "Productive Hours", render: (row: RowData) => row.productive_hr },
    { id: "billable_by_emp", label: "Billable Hours", render: (row: RowData) => row.billable_by_emp },
    { id: "date", label: "Date", render: (row: RowData) => row.date },
    {
      id: "action",
      label: "Action",
      render: (row: RowData) => (
        <>
          <IconButton onClick={() => handleEditClick(row)} size="small" style={{ fontSize: 16 }}>
            <EditIcon fontSize="inherit" />
            {t("pmDashboard:edit")}
          </IconButton>
        </>
      ),
    },
  ];

  const rows: RowData[] = timeEntries?.map((timeEntries) => ({
    id: timeEntries.id,
    project: timeEntries.project,
    project_name: timeEntries.project_name,
    task_title: timeEntries.task_title,
    productive_hr: timeEntries.productive_hr,
    billable_by_emp: timeEntries.billable_by_emp,
    date: timeEntries.date,
    action: ["Edit"],
  }));

  const handleEditClick = (row: RowData) => {
    console.log("row", row);
    setRowDatas(row);
    setIsDrawerOpen(true);
  };

  const handleApproveClick = (row: RowData) => {
    console.log("Approve clicked for", row);
  };
  return (
    <>
      <Box className={classes.contentArea}>
        <Box className={classes.commonCard}>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className={classes.tableHeadRow}>
                    {columns?.map((column) => <TableCell key={column.id}>{column.label}</TableCell>)}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows?.map((row, rowIndex) => {
                    return (
                      <TableRow key={rowIndex}>
                        {columns?.map((column) => {
                          return <TableCell key={column.id}>{column.render(row)}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <AddPopup heading={"Edit Worked Hours"} open={isDrawerOpen} onClose={handleCloseDrawer}>
            <EditHour onClose={handleCloseDrawer} rowDatas={rowDatas} />
          </AddPopup>
        </Box>
      </Box>
    </>
  );
};

export default TimeSheetContentArea;
