import { Post } from "./axios";

const baseUrl = "/account";
const LoginAPIURL = `${baseUrl}/login/`;
const SignUPAPIURL = `${baseUrl}/signup`;

// const ResetPasswordUrl = '/resetpassword';

const LoginAPI = (payload: any) => {
  return Post({ url: LoginAPIURL, request: payload });
};
const SignUP = (payload: any) => {
  return Post({ url: SignUPAPIURL, request: payload });
};

export { LoginAPI, SignUP };
