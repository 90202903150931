import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  userCard: {
    border: `2px solid ${theme.palette.decorative.white.shade7}`,
    borderRadius: "8px",
    margin: "5px",
    width: "420px",
    height: "250px",
    background: theme.palette.decorative.blue.shade6,
  },
  userImg: {
    height: "60px",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    borderRadius: "5px",
    color: theme.palette.decorative.white.main,
    fontWeight: 500,
    letterSpacing: "2px",
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "16px !important",
  },
  blackText: {
    color: "darkslategray",
  },
  userTitle: {
    marginTop: "0px",
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "10px",
    marginRight: "0px",
  },
  userCardHead: {
    display: "flex",
    padding: "15px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  users: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
