import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  projectCard: {
    padding: theme.spacing(3),
  },
  projectDetailsContainer: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: theme.shadows[5],
    borderRadius: theme.shape.borderRadius,
  },
  comments: {
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  editButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },

  contentArea: {
    padding: "15px",
  },
  commonCard: {
    borderRadius: "8px",
    background: theme.palette.decorative.white.main,
    boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25)",
    overflow: "hidden",
  },
  navLinks: {
    height: "100%",
    padding: "0px 10px 150px 10px",
    overflow: "auto",
    paddingTop: "2em",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& li": {
      position: "relative",
      listStyle: "none",
      transition: "all 0.4s ease",
      borderRadius: "8px",
      marginBottom: "5px",
      "&:hover": {
        background: theme.palette.decorative.blue.light,
      },
      "& .icon-link": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      "& i": {
        height: "50px",
        minWidth: "55px",
        textAlign: "center",
        lineHeight: "50px",
        color: theme.palette.decorative.white.main,
        fontSize: "20px",
        cursor: "pointer",
        transition: "all 0.3s ease",
        position: "relative",
        borderRadius: "8px",
        marginRight: "10px",
      },
      "&.showMenu i.arrow": {
        transform: "rotate(-180deg)",
      },
      "&.close i.arrow": {
        display: "none",
      },
      "& a": {
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
      },
      "& .link_name": {
        fontSize: "18px",
        fontWeight: 400,
        color: theme.palette.decorative.black.main,
        transition: "all 0.4s ease",
        "&.close": {
          opacity: 0,
          pointerEvents: "none",
        },
      },
      "& .sub-menu": {
        padding: "10px 0px 10px 57px",
        background: theme.palette.decorative.blue.shade2,
        display: "none",
        borderRadius: "8px",
        "&.showMenu": {
          display: "block",
        },
        "& a": {
          color: theme.palette.decorative.white.main,
          fontSize: "15px",
          padding: "5px 0",
          whiteSpace: "nowrap",
          opacity: 0.6,
          transition: "all 0.3s ease",
        },
        "& a:hover": {
          opacity: 1,
        },
        "&.close": {
          position: "absolute",
          left: "100%",
          top: "-10px",
          margin: "0",
          padding: "10px 20px",
          borderRadius: "0 6px 6px 0",
          opacity: 0,
          pointerEvents: "none",
        },
        "&.close.showMenu": {
          top: "0",
          opacity: 1,
          pointerEvents: "auto",
          transition: "all 0.4s ease",
        },
        "& .link_name": {
          display: "none",
        },
        "&.close .link_name": {
          fontSize: "18px",
          opacity: 1,
          display: "block",
        },
      },
      "&:hover .link_name": {
        color: `${theme.palette.decorative.white.main} important`,
      },
    },
  },
  navBorder: {
    borderBottom: `.5px solid ${theme.palette.decorative.white.shade2}`,
  },
  navLink: {
    fontSize: "18px",
    padding: "1% 5%",
    fontWeight: "500px",
    color: theme.palette.decorative.black.shade1,
    border: "0",
    position: "relative",
    background: "none",
    "&.active": {
      color: theme.palette.decorative.blue.main,
      "&::before": {
        width: "100%",
        content: '""',
        position: "absolute",
        left: "0px",
        right: "0px",
        height: "4px",
        background: theme.palette.decorative.blue.shade1,
        bottom: "0px",
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
      },
    },
  },
  tabPane: {
    padding: "10px",
  },
  p20: {
    padding: "20px",
  },
  projectListDashboard: {
    display: "flex",
    border: `1px solid ${theme.palette.decorative.white.shade1}`,
    padding: "10px",
    borderRadius: "8px",
    marginTop: "10px",
    "& img": {
      width: "50px",
      height: "50px",
      marginRight: "15px",
    },
  },
  commonTag: {
    borderRadius: "5px",
    border: `1px solid ${theme.palette.decorative.white.shade2}`,
    padding: "0px 8px",
    fontSize: "12px",
    color: theme.palette.decorative.white.shade3,
    height: "30px",
  },

  HeaderContent: {
    display: "flex",
    justifyContent: "space-between",
    color: "blue",
    width: "100%",
    marginRight: "20px",
  },
  addButton: {
    "& .MuiSvgIcon-root": {
      color: "dodgerblue",
    },
  },
  bodyText: {
    fontFamily: "sans-serif",
    fontSize: "15px",
    color: "dodgerblue",
  },
  userNameTxt: {
    color: theme.palette.decorative.blue.main,
    fontWeight: "600",
    fontSize: "26px",
  },
  Text: {
    color: theme.palette.decorative.white.shade4,
    fontWeight: "600",
    fontSize: "26px",
    margin: "0 15px",
  },

  searchPaginateContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  projectAvatar: {
    // verticalAlign: "top",
    marginLeft: "20px",
    // display: "flex",
    alignItems: "center",
    // gap: "15px",
    display: "flex",
    marginTop: "15px",
    justifyContent: "space-between",
  },

  heades: {
    display: "flex",
    gap: "20px",
  },
  last: {
    display: "flex",
    alignItems: "center",
  },
  noWarpf: {
    // width: "190px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  projectDateTeam: {
    display: "flex",
    gap: "15px",
  },
  date: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "20px",
  },
  projectDateText: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "end",
  },
  projectDateLabel: {
    color: theme.palette.decorative.white.shade3,
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "end",
  },
  projectNameStatus: {
    display: "flex",
    gap: "16px",
    justifyContent: "space-between",
    marginBottom: "9px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  projectStatus: { display: "grid", marginRight: "30px" },
  projectStatus111: { display: "flex", marginTop: "20px" },

  fixedWidthChip: {
    // width: "100px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // display: "inline-block",
  },
  bigLabelBox: {
    fontSize: "14px",
    marginBottom: "10px",
    // display: "flex",
    // justifyContent: "flex-end",
    display: "grid",
    marginLeft: "30px",
  },
  projectStatussss: {
    display: "flex",
    justifyContent: "end",
    marginRight: "10px",
  },
  noWarp: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "inline-block",
  },
  bigLabel: {
    color: theme.palette.decorative.white.shade3,
    fontFamily: "Poppins",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    whiteSpace: "nowrap",
  },
  tableHeadRow: {
    backgroundColor: "darkgrey",
  },
  smallCalendar: {
    width: "200px",
    height: "200px",
    fontSize: "50px",
  },
  timeSheetPaginationWarp: {
    display: "flex",
    justifyContent: "end",
  },
  timeSheetPagination: {
    display: "flex",
    justifyContent: "space-evenly",
    gap: "13px",
  },
  teamMembersBox: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));

export default useStyles;
