import React, { SetStateAction, useEffect, useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useStyles from "../style";
import InputController from "../../common/components/input";
import DropdownController from "../../common/components/dropDown";
import { AllDepAPI } from "../../../serviceHandlers/userTeamServices";
import { AllPmAPI, ListAllEmployeesByPmAPI } from "../../../serviceHandlers/userProfileServices";
import { AllClientAPI } from "../../../serviceHandlers/clientServices";
import DatePickerController from "components/common/components/datePicker";
import schema from "./schema";
import EnterToFindModal from "components/common/components/enterToFindModal";

import { useTranslation } from "react-i18next";
import { useSnackBar } from "../../common/useSnack";
import { openSnack } from "store/common/appSlice";
import { useDispatch } from "react-redux";
import { IsUserCreated } from "store/common/createdUpdatedSlice";
import ButtonController from "components/common/components/button";
import { format } from "date-fns";
import { EditProjectAPI, ProjectsDetailAPI } from "serviceHandlers/projectServices";
import { useParams } from "react-router-dom";
import { getConstant, getConstantByName } from "components/common/config/constants";
import { usePermissions } from "utils/hooks/usePermissions";
import { Roles } from "utils/hooks/usePermissions";

interface Team {
  id: number;
  team_name: string;
}
interface Pm {
  id: number;
  pm: string;
}
interface Status {
  id: number;
  name: string;
}
interface Client {
  id: number;
  name: string;
}

interface Emp {
  id: number;
  profile_picture?: string;
  designation?: string;
  team_name?: string;
  location?: string;
  name: string;
  mailid?: string;
}

interface ProjectData {
  id?: number;
  project_name?: string;
  status?: string;
  comments?: string;
  type?: string;
  start_date?: string;
  end_date?: string;
  estimate_hours?: string;
  actual_hours?: string;
  archived_project?: boolean;
  internal_project?: boolean;
  client_name?: string;
  team_name?: string;
  bdm?: string;
  pm?: string;
  tl?: string;
  team_members?: string[];
}

const EditProject: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [team, setTeam] = useState<Team[]>([]);
  const [pm, setPm] = useState<Pm[]>([]);
  const [client, setClient] = useState<Client[]>([]);
  const [employee, setEmp] = useState<Emp[]>([]);
  const [selectedEmp, setSelectedEmp] = useState<number[]>([]);
  const status = getConstant("status");
  const [defaultStatus, setDefaultStatus] = useState<string>("");
  const [defaultType, setDefaultType] = useState<string>("");
  const [startDate, setStartDate] = useState<any>(new Date());
  const type = getConstant("type");
  const { role }: { role: string } = usePermissions();

  const classes = useStyles();

  const { snackComponent } = useSnackBar();
  const { projectId } = useParams<{ projectId: string }>();
  const [project, setProject] = useState<ProjectData>({});

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await ProjectsDetailAPI(projectId);
        setProject(response?.data || {});

        if (response?.data?.team_members && response?.data?.team_members.length > 0) {
          setSelectedEmp((prevSelectedEmp: any) => Array.from(new Set([...prevSelectedEmp, ...(response?.data.team_members ?? [])])));
        }
      } catch (error) {
        console.error("Error fetching project details", error);
      }
    };

    fetchProjectDetails();
  }, [projectId]);

  useEffect(() => {
    if (project.status) {
      const status = getConstantByName("status", project.status);
      setDefaultStatus(status.name);
    }
    if (project.type) {
      const type = getConstantByName("type", project.type);
      setDefaultType(type.name);
    }
  }, [project, type]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };

  const dispatch = useDispatch();

  const { handleSubmit } = methods;
  const formatDate = (date: any) => {
    return date.split("T")[0];
  };
  const onSubmit = async (data: any) => {
    try {
      data.start_date = format(new Date(data.start_date), "yyyy-MM-dd");
      data.end_date = format(new Date(data.end_date), "yyyy-MM-dd");
      data.id = projectId;

      await EditProjectAPI(data).then((response) => {
        if (response.status) {
          dispatch(IsUserCreated(true));
          showSnackbar("success", "Project updated successfully!");
        } else {
          showSnackbar("error", "Failed to add project. Please try again.");
        }
      });
      onClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("error", "An error occurred. Please try again.");
    }
  };
  const { t } = useTranslation(["common"]);

  const listAllDepAPI = async () => {
    try {
      const response = await AllDepAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllPmAPI = async () => {
    try {
      const response = await AllPmAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllClientAPI = async () => {
    try {
      const response = await AllClientAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllEmpLAPI = async () => {
    try {
      const response = await ListAllEmployeesByPmAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    listAllDepAPI().then((response) => {
      setTeam(response?.data);
    });
    listAllPmAPI().then((response) => {
      setPm(response?.data);
    });
    listAllClientAPI().then((response) => {
      setClient(response?.data);
    });
    listAllEmpLAPI().then((response) => {
      setEmp(response?.data);
    });
  }, []);

  const isPM = role === Roles.PM;

  return (
    <FormProvider {...methods}>
      {defaultType && project.project_name && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box className={classes.spaceBetween}>
            <InputController
              name="project_name"
              label={"Project Name"}
              placeHolder={"Ex. Project x"}
              isRequired
              defaultValue={project.project_name}
              isDisabled={isPM}
            />
          </Box>

          <Box className={classes.spaceBetween}>
            <DropdownController
              arrayData={type}
              defaultValue={defaultType}
              name={"name"}
              label={"Type"}
              dataKey={"type"}
              isRequired
              isDisabled={isPM}
            />
          </Box>

          <Box className={classes.spaceBetween}>
            <DropdownController
              arrayData={client}
              defaultValue={project.client_name}
              name={"name"}
              label={"Client"}
              dataKey={"client_name"}
              isId={true}
              isRequired
              isDisabled={isPM}
            />
          </Box>
          <Box className={classes.spaceBetween}>
            <DropdownController
              arrayData={status}
              defaultValue={defaultStatus}
              name={"name"}
              label={"Status"}
              dataKey={"status"}
              isRequired
              isDisabled={isPM}
            />
          </Box>

          <Box className={classes.spaceBetween}>
            <DatePickerController
              label={"Start Date"}
              defaultValue={project.start_date}
              dataKey={"start_date"}
              isRequired
              isDisabled={isPM}
              disablePast
            />
          </Box>
          <Box className={classes.spaceBetween}>
            <DatePickerController label={"End Date"} defaultValue={project.end_date} dataKey={"end_date"} isRequired isDisabled={isPM} disablePast />
          </Box>

          <Box className={classes.spaceBetween}>
            <InputController
              name="estimate_hours"
              label={"Estimate Hours"}
              placeHolder={"Estimate Hours"}
              isRequired
              defaultValue={project.estimate_hours}
              isDisabled={isPM}
            />
          </Box>
          <Box className={classes.spaceBetween}>
            <InputController
              name="actual_hours"
              label={"Actual Hours"}
              placeHolder={"Actual Hours"}
              isRequired
              defaultValue={project.actual_hours}
              isDisabled={isPM}
            />
          </Box>

          <Box className={classes.spaceBetween}>
            <DropdownController
              arrayData={team}
              defaultValue={project.team_name}
              name={"team_name"}
              label={"Department"}
              dataKey={"team_name"}
              isId={true}
              isRequired
              isDisabled={isPM}
            />
          </Box>
          <Box className={classes.spaceBetween}>
            <DropdownController
              arrayData={pm}
              defaultValue={project.pm}
              name={"pm"}
              label={"Reporting Manager"}
              dataKey={"pm"}
              isId={true}
              isRequired
              isDisabled={isPM}
            />
          </Box>
          {isPM && (
            <Box className={classes.spaceBetween}>
              <EnterToFindModal
                setSelectedEmp={setSelectedEmp}
                checkArray={employee}
                placeholder="Enter Name to find Member"
                title="Assign To"
                selectedEmp={selectedEmp}
                name="team_members"
                label="Select Members"
              />
            </Box>
          )}
          <Box className={classes.spaceBetween}>
            <ButtonController name="submit" label="Submit" />
            {snackComponent()}
          </Box>
        </form>
      )}
    </FormProvider>
  );
};

export default EditProject;
