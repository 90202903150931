import projectIcon from "../../../assets/images/project-icon.svg";
import teamIcon from "../../../assets/images/team-icon.svg";
import ReportIcon from "../../../assets/images/report-icon.svg";
import dashboardIcon from "../../../assets/images/dashboard-icon.svg";
import userIcon from "../../../assets/images/user.svg";
import clientIcon from "../../../assets/images/client-icon.svg";
import { Roles } from "utils/hooks/usePermissions";

const routeConfig = {
  [Roles.BDM]: {
    sidebar: [
      {
        menu: "Dashboard",
        link: "/dashboard",
        icon: dashboardIcon,
      },
      {
        menu: "Profile",
        link: "/profile",
        icon: userIcon,
      },
      {
        menu: "Project",
        link: "/list-projects",
        icon: projectIcon,
        subMenu: [
          {
            name: "Add Project",
            component: "AddProject",
            link: "/list-projects",
          },
        ],
      },
      // {
      //   menu: "Team",
      //   link: "#",
      //   icon: teamIcon,
      // },
      {
        menu: "Clients",
        link: "/list-clients",
        icon: clientIcon,
        subMenu: [
          {
            name: "Add Client",
            component: "AddClient",
            link: "/list-clients",
          },
        ],
      },
      // {
      //   menu: "Timesheet",
      //   link: "/time-sheet-approval",
      //   icon: projectIcon,
      // },
      {
        menu: "Reports",
        link: "#",
        icon: ReportIcon,
      },
    ],
  },
  [Roles.PM]: {
    sidebar: [
      {
        menu: "Dashboard",
        link: "/dashboard",
        icon: dashboardIcon,
      },
      {
        menu: "Profile",
        link: "/profile",
        icon: userIcon,
      },
      {
        menu: "Project",
        link: "/list-projects",
        icon: projectIcon,
        subMenu: [
          // {
          //   name: "Add Project",
          //   link: "/list-projects",
          //   component: "AddProject",
          // },

          {
            name: "Add Temporary Member",
            link: "/list-projects",
            component: "AddTempMemberToProject",
          },
        ],
      },
      {
        menu: "Temporary Member",
        link: "/temporary-members",
        icon: dashboardIcon,
        subMenu: [
          // {
          //   name: "Add Project",
          //   link: "/list-projects",
          //   component: "AddProject",
          // },
          {
            name: "Incoming Requests",
            link: "/temporary-members/requested",
            // component: "AddTempMember",
          },
          {
            name: "Request",
            link: "/temporary-members",
            component: "AddTempMember",
          },
        ],
      },
      // {
      //   menu: "Team",
      //   link: "/temporary-member",
      //   icon: teamIcon,
      // },
      {
        menu: "Reports",
        link: "#",
        icon: ReportIcon,
      },
      {
        menu: "Timesheet",
        link: "/time-sheet-approval",
        icon: projectIcon,
      },
    ],
  },
  [Roles.TeamMember]: {
    sidebar: [
      {
        menu: "Dashboard",
        link: "/dashboard",
        icon: dashboardIcon,
      },
      {
        menu: "Profile",
        link: "/profile",
        icon: userIcon,
      },
      {
        menu: "Project",
        link: "/list-projects",
        icon: projectIcon,
      },
      {
        menu: "Timesheet",
        link: "/time-sheet",
        icon: projectIcon,
      },
    ],
  },
  [Roles.HR]: {
    sidebar: [
      {
        menu: "Dashboard",
        link: "/dashboard",
        icon: dashboardIcon,
      },
      {
        menu: "Profile",
        link: "/profile",
        icon: userIcon,
      },
      {
        menu: "Employees",
        link: "/employees",
        icon: teamIcon,
      },
    ],
  },
};

export default routeConfig;
