import { Get, Post, Patch } from "./axios";

const baseUrl = "/teams_temporary";
const listRequestedTemporaryTeamMemberURL = `${baseUrl}/temporary_team_member_approval/`;
const addTemporaryTeamMemberURL = `${baseUrl}/add_to_project`;
// const selectTemporaryTeamMemberURL = `${baseUrl}/select_temporary_team_member/`;
const selectTemporaryTeamMemberURL = `${baseUrl}/add_as_temporary_team_member/`;

const temporaryTeamByPmURL = `${baseUrl}/temporary_team_member_requested/`;

const listRequestedTemporaryTeamMemberAPI = (payload: any) => {
  return Get({ url: listRequestedTemporaryTeamMemberURL, request: payload });
};

const addTemporaryTeamMemberAPI = (payload: any) => {
  return Post({ url: addTemporaryTeamMemberURL, request: payload });
};

// const selectTemporaryTeamMemberAPI = (payload: any) => {
//   return Patch({ url: selectTemporaryTeamMemberURL, request: payload });
// };

const selectTemporaryTeamMemberAPI = (payload: any) => {
  return Post({ url: selectTemporaryTeamMemberURL, request: payload });
};

const temporaryTeamByPmAPI = (payload: any) => {
  return Get({ url: temporaryTeamByPmURL, request: payload });
};

export { listRequestedTemporaryTeamMemberAPI, addTemporaryTeamMemberAPI, selectTemporaryTeamMemberAPI, temporaryTeamByPmAPI };
