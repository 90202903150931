import { Get, Patch, Post } from "./axios";

const baseUrl = "/profile";
const CreateInternalProjectURL = `${baseUrl}/create_internal_project`;
const AddEmpAPIURL = `${baseUrl}/add_new_employee/`;
const EmpListAPIURL = `${baseUrl}/list_employees/`;
const EmpListAPIPaginatedURL = `${baseUrl}/list_all_employee/`;
const AllDesigAPIURL = `${baseUrl}/get_all_designations/`;
const AllPmAPIURL = `${baseUrl}/get_all_pm/`;
const ViewProfileById = `${baseUrl}/view_profile/`;
const BdmListAPIURL = `${baseUrl}/get_bdm/`;
const PmListAPIURL = `${baseUrl}/get_pm/`;
const TlListAPIURL = `${baseUrl}/get_tl/`;
const ViewProfileAPIURL = `${baseUrl}/view_profile/`;
const ListAllEmployeesByPmURL = `${baseUrl}/list_employees_by_pm/`;
const UpdateEmployeeURL = `${baseUrl}/update_employee/`;
const UpdateProfileURL = `${baseUrl}/update_profile/`;

const CreateInternalProjectAPI = (payload: any) => {
  return Post({ url: CreateInternalProjectURL, request: payload });
};

const EmpListAPI = (payload: any) => {
  const EmpListAPIFilterURL = payload.exclude_assigned_to ? `${EmpListAPIURL}?exclude_assigned_to=${payload.exclude_assigned_to}` : EmpListAPIURL;
  return Get({ url: EmpListAPIFilterURL, request: payload });
};

const EmpListAPIPaginate = (payload: any) => {
  return Get({ url: `${EmpListAPIPaginatedURL}?${payload}`, request: {} });
};

const AddEmpAPI = (payload: any) => {
  return Post({ url: AddEmpAPIURL, request: payload });
};

const AllDesigAPI = (payload: any) => {
  return Get({ url: AllDesigAPIURL, request: payload });
};

const AllPmAPI = (payload: any) => {
  return Get({ url: AllPmAPIURL, request: payload });
};

const ViewProfileByIdAPI = (payload: any) => {
  return Get({ url: `${ViewProfileById}${payload}`, request: {} });
};

const BdmListAPI = (payload: any) => {
  return Get({ url: `${BdmListAPIURL}${payload}`, request: {} });
};

const PmListAPI = (payload: any) => {
  return Get({ url: `${PmListAPIURL}${payload}`, request: {} });
};

const TlListAPI = (payload: any) => {
  return Get({ url: `${TlListAPIURL}${payload}`, request: {} });
};

const ViewProfileAPI = (payload: any = {}) => {
  return Get({ url: ViewProfileAPIURL, request: payload });
};

const ListAllEmployeesByPmAPI = (payload: any = {}) => {
  return Get({ url: ListAllEmployeesByPmURL, request: payload });
};

const UpdateEmployeeAPI = (payload: any = {}) => {
  return Patch({ url: `${UpdateEmployeeURL}${payload.id}/`, request: payload.data });
};

const UpdateProfileAPI = (payload: any = {}) => {
  return Patch({ url: UpdateProfileURL, request: payload });
};

export {
  CreateInternalProjectAPI,
  EmpListAPIPaginate,
  EmpListAPI,
  AddEmpAPI,
  AllDesigAPI,
  AllPmAPI,
  ViewProfileByIdAPI,
  BdmListAPI,
  PmListAPI,
  TlListAPI,
  ViewProfileAPI,
  ListAllEmployeesByPmAPI,
  UpdateEmployeeAPI,
  UpdateProfileAPI,
};
