import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import InputController from "../../common/components/input";
import DropdownController from "../../common/components/dropDown";
import { AddEmpAPI } from "../../../serviceHandlers/userProfileServices";
import { AllDesigAPI } from "../../../serviceHandlers/userProfileServices";
import { AllDepAPI } from "../../../serviceHandlers/userTeamServices";
import { AllPmAPI } from "../../../serviceHandlers/userProfileServices";
import useStyles from "../style";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addSchema } from "./schema";
import ButtonController from "components/common/components/button";
import ChipInput from "components/common/components/chipTag";
import FileUploadButton from "components/common/components/fileUpload";
import { useDispatch } from "react-redux";
import { IsUserCreated } from "store/common/createdUpdatedSlice";
import { useSnackBar } from "../../../components/common/useSnack";
import { openSnack } from "store/common/appSlice";

interface Designation {
  id: number;
  designation: string;
}
interface AssignedTo {
  id: number;
  assigned_to: string;
}
interface Team {
  id: number;
  team_name: string;
}
interface Role {
  id: number;
  role: string;
}
interface Location {
  id: number;
  name: string;
}

const AddUserPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [desig, setDesig] = useState<Designation[]>([]);
  const [team, setTeam] = useState<Team[]>([]);
  const [pm, setPm] = useState<AssignedTo[]>([]);
  const [locations, setLocations] = useState<Location[]>([
    { id: 1, name: "Cochin" },
    { id: 2, name: "Trivandrum" },
    { id: 3, name: "Remote" },
  ]);
  const [roles, setRoles] = useState<Role[]>([
    { id: 1, role: "HR" },
    { id: 2, role: "BDM" },
    { id: 3, role: "TL" },
    { id: 4, role: "PM" },
    { id: 5, role: "TeamMember" },
  ]);

  const [imageUploadStatus, setImageUploadStatus] = useState<string | null>(null);
  const classes = useStyles();
  const { snackComponent } = useSnackBar();

  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };

  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(addSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
    },
  });

  const { handleSubmit } = methods;

  const onSubmitError = async (error: any) => {
    showSnackbar("error", "Oops!! You've missed something. Please check once again");
    const firstErrorField = Object.keys(error)[0];
    // Focus on the first error field
    document.getElementsByName(firstErrorField)[0].focus();
    // Scroll to the first error field
    document.getElementsByName(firstErrorField)[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const onSubmit = async (data: any) => {
    try {
      data.employee_code = `TV${data.employee_code}`;
      data.skills = data?.skills?.join(", ") || data.skills;
      if (data.profile_picture === "") {
        delete data.profile_picture;
      }
      await AddEmpAPI(data).then((response) => {
        if (response.status) {
          dispatch(IsUserCreated(true));
          showSnackbar("success", "User created successfully!");
        } else {
          showSnackbar("error", "Failed to create user. Please try again.");
        }
      });
      onClose();
    } catch (error: any) {
      console.error("Error submitting data1:", error);
      if (error?.response?.data && error.code === "ERR_BAD_REQUEST") {
        Object.keys(error.response.data).forEach((e) => {
          showSnackbar("error", error?.response?.data[e][0]);
        });
      } else {
        showSnackbar("error", "An error occurred. Please try again later.");
      }
    }
  };

  const { t } = useTranslation(["userDrawer", "common"]);

  const listAllDesigAPI = async () => {
    try {
      const response = await AllDesigAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllDepAPI = async () => {
    try {
      const response = await AllDepAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllPmAPI = async () => {
    try {
      const response = await AllPmAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    listAllDesigAPI().then((response) => {
      setDesig(response?.data);
    });
    listAllDepAPI().then((response) => {
      setTeam(response?.data);
    });
    listAllPmAPI().then((response) => {
      setPm(response?.data);
    });
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
        <Box className={classes.spaceBetween}>
          <Typography className={classes.label}>{t("upphoto")}</Typography>
          <FileUploadButton label="Upload file" name="profile_picture" type="file" onUploadStatusChange={(status) => setImageUploadStatus(status)} />
          {imageUploadStatus && (
            <Typography variant="caption" color="success">
              {imageUploadStatus}
            </Typography>
          )}
        </Box>
        <Box id="firstname" className={classes.spaceBetween}>
          <InputController name="first_name" label={"First Name"} placeHolder={"Ex. Luke"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <InputController name="last_name" label={"Last Name"} placeHolder={"Ex. Mark"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <InputController name="email" label={"Email ID"} placeHolder={"Ex.luke@gmail.com"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <InputController name="employee_code" label={"Employee Code"} placeHolder={"Ex.TVXXXX"} isRequired prefix="TV" />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController
            arrayData={desig}
            defaultValue={1}
            name={"designation"}
            label={"Designation"}
            dataKey={"designation"}
            isId={true}
            isRequired
          />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController arrayData={team} defaultValue={1} name={"team_name"} label={"Department"} dataKey={"team"} isId={true} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController
            arrayData={pm}
            defaultValue={1}
            name={"pm"}
            label={"Reporting Manager"}
            dataKey={"assigned_to"}
            isId={true}
            isRequired
          />
        </Box>
        <ChipInput defaultSkills={[]} label="Skill" dataKey="skills" isRequired />
        <Box className={classes.spaceBetween}>
          <DropdownController arrayData={locations} defaultValue={"Cochin"} name={"name"} label={"Location"} dataKey={"location"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <DropdownController arrayData={roles} defaultValue={"HR"} name={"role"} label={"Role"} dataKey={"role"} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <InputController name="address" label={"Address"} placeHolder={"Ex. Luke s"} ismultiline={true} isRequired />
        </Box>
        <Box className={classes.spaceBetween}>
          <ButtonController name="submit" label="Submit" />
          {snackComponent()}
        </Box>
      </form>
    </FormProvider>
  );
};

export default AddUserPopup;
