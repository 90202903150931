import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Typography, Button } from "@mui/material";
import useStyles from "../../Popup/style";

interface ButtonControllerProps {
  name: string;
  label: string;
}

const ButtonController: React.FC<ButtonControllerProps> = ({ name, label }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Button {...field} type="submit" variant="contained" size="large" fullWidth>
            {label}
          </Button>
        )}
      />
    </>
  );
};

export default ButtonController;
