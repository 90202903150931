import React, { useState, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { FormGroup, IconButton, TextField, Tooltip } from "@mui/material";
import BasicModal from "./modal";
import useStyles from "../../Popup/style";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

interface EnterToFindModalProps {
  checkArray?: any;
  placeholder: string;
  title: string;
  setSelectedEmp: React.Dispatch<React.SetStateAction<number[]>>;
  selectedEmp: number[];
  name: string;
  isRequired?: boolean;
  label: string;
}

const EnterToFindModal: React.FC<EnterToFindModalProps> = ({
  checkArray,
  placeholder,
  title,
  setSelectedEmp,
  selectedEmp,
  name,
  isRequired = false,
  label,
}) => {
  // Find the common elements between checkArray and selectedEmp
  const commonIds = checkArray.filter((employee: any) => selectedEmp.includes(employee.id));

  // Set the starting index for each common element
  commonIds.forEach((commonEmployee: any) => {
    const index = checkArray.findIndex((employee: any) => employee.id === commonEmployee.id);
    if (index !== -1) {
      checkArray.splice(index, 1); // Remove the element from its current position
      checkArray.unshift(commonEmployee); // Add it to the beginning of the array
    }
  });

  const classes = useStyles();
  const [input, setInput] = useState<string>("");
  const [modalAddPeople, setModalAddPeople] = useState<boolean>(false);

  const filteredArray = checkArray.filter((item: any) => item.name.toLowerCase().includes(input.toLowerCase()));
  const modalList = input === "" ? checkArray : filteredArray;

  const handleTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setSelectedEmp(selectedEmp);
  };

  const handleCheckboxChange = (field: any, id: number) => {
    const updatedSelectedEmp = selectedEmp.includes(id) ? selectedEmp.filter((item) => item !== id) : [...selectedEmp, id];

    setSelectedEmp(updatedSelectedEmp);
    field.onChange(updatedSelectedEmp);
  };

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const isError = errors[name];

  const handleAddNewClick = () => {
    setModalAddPeople(true);
  };

  return (
    <>
      <Typography className={classes.label}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </Typography>
      <Box className={`${classes.spaceBetween} ${classes.addEmp}`}>
        {selectedEmp.length > 0 && (
          <Box className={classes.addEmpAvatar}>
            {selectedEmp.slice(0, 3)?.map((item: any) => {
              const data = checkArray.find((emp: any) => emp.id === item);
              return (
                data && (
                  <Avatar alt={data.name} src={data.profile_picture ? data.profile_picture : `URL_FOR_YOUR_AVATAR_IMAGE_${data.name.charAt(0)}`}>
                    {data.name.charAt(0)}
                  </Avatar>
                )
              );
            })}
            {selectedEmp.length > 3 && <Typography className={classes.bodyText}>{`+${selectedEmp.length - 3} others`}</Typography>}
          </Box>
        )}
        <Tooltip title={isError ? (errors[name] as FieldError)?.message : ""} arrow>
          <IconButton className={classes.addButton} onClick={handleAddNewClick}>
            <AddCircleOutlineIcon />
            <Typography variant="body1" className={classes.bodyText} style={isError && { color: "red" }}>
              Add New
            </Typography>
          </IconButton>
        </Tooltip>
      </Box>
      {modalAddPeople && (
        <BasicModal open={modalAddPeople} setOpen={setModalAddPeople} title={title}>
          <Box className={classes.enterToFindTextField}>
            <TextField placeholder={placeholder} variant="outlined" size="small" fullWidth onChange={handleTextFieldChange} />
          </Box>
          <Controller
            name={name}
            control={control}
            defaultValue={[]}
            render={({ field }) => (
              <FormGroup>
                <Box className={classes.FormGroup}>
                  {modalList?.map((item: any) => (
                    <Box key={item.id} className={classes.enterToFindAvatarBox} onClick={() => handleCheckboxChange(field, item.id)}>
                      <Avatar
                        alt={item.name}
                        src={item.profile_picture ? item.profile_picture : `URL_FOR_YOUR_AVATAR_IMAGE_${item.name.charAt(0)}`}
                        className={`${classes.enterToFindAvatar} ${selectedEmp.includes(item.id) && "checked"}`}
                      >
                        {item.name.charAt(0)}
                      </Avatar>
                      <Typography className={selectedEmp.includes(item.id) ? classes.enterToFindNameChecked : classes.enterToFindNameNotChecked}>
                        {item.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </FormGroup>
            )}
          />
        </BasicModal>
      )}
    </>
  );
};

export default EnterToFindModal;
