import React, { useEffect, useState, ReactNode } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useStyles from "./style";
import { onDashboardClose } from "store/dashboard/sidebarSlice";
import { errorConfig } from "components/common/config/config";

interface SideBarProps {
  contentComponent: ReactNode;
  headerComponent: ReactNode;
}

const ContentTab: React.FC<SideBarProps> = ({ contentComponent = errorConfig.errorContent, headerComponent = errorConfig.errorHeader }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dashboardClosed } = useSelector((state: any) => state.dashboard.sidebar);

  const [dashboardStatus, setDashboardStatus] = useState(dashboardClosed);

  const handleHideSlider = () => {
    dispatch(onDashboardClose(!dashboardStatus));
  };

  useEffect(() => {
    setDashboardStatus(dashboardClosed);
  }, [dashboardClosed]);

  return (
    <Box component="section" className={`${classes.homeSection} ${dashboardStatus && "close"}`}>
      <Box className={classes.homeContent}>
        <IconButton className={classes.menuIcon} onClick={handleHideSlider}>
          <MenuIcon fontSize="large" />
        </IconButton>
        {headerComponent}
      </Box>
      {contentComponent}
    </Box>
  );
};

export default ContentTab;
