import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddClient from "../Popup/client/addClient";
import useStyles from "./style";
import AddPopup from "../Popup";
import { useTranslation } from "react-i18next";
import AddHour from "../Popup/TimeSheet/addTimeSheet";

const HeaderArea: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleAddNewClick = () => {
    setIsDrawerOpen(true);
  };

  const { t } = useTranslation("timeSheet");

  const classes = useStyles();
  return (
    <>
      <div className={classes.HeaderContent}>
        <Box component="span" className={classes.Text}>
          {t("timeSheet")}
        </Box>
        <Box>
          <IconButton className={classes.addButton} onClick={handleAddNewClick}>
            <AddCircleOutlineIcon />
            <Typography variant="body1" className={classes.bodyText}>
              {t("newhour")}
            </Typography>
          </IconButton>
        </Box>
      </div>
      <AddPopup heading={"Add Worked Hours"} open={isDrawerOpen} onClose={handleCloseDrawer}>
        <AddHour onClose={handleCloseDrawer} />
      </AddPopup>
    </>
  );
};

export default HeaderArea;
