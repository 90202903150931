import SideBar from "./SideBar";
import useStyles from "./style";

export const AppLayout = ({ children }: { children: React.ReactElement }) => {
  const classes = useStyles();
  return (
    <>
      <SideBar />
      <div className={classes.dashboardWrap}>{children}</div>
    </>
  );
};
