import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import InputController from "../../common/components/input";
import DropdownController from "../../common/components/dropDown";
import { UpdateEmployeeAPI, ViewProfileByIdAPI } from "../../../serviceHandlers/userProfileServices";
import { AllDesigAPI } from "../../../serviceHandlers/userProfileServices";
import { AllDepAPI } from "../../../serviceHandlers/userTeamServices";
import { AllPmAPI } from "../../../serviceHandlers/userProfileServices";
import useStyles from "../style";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { editSchema } from "./schema";
import ButtonController from "components/common/components/button";
import ChipInput from "components/common/components/chipTag";
import FileUploadButton from "components/common/components/fileUpload";
import { useDispatch } from "react-redux";
import { IsUserUpdated } from "store/common/createdUpdatedSlice";
import { useSnackBar } from "../../common/useSnack";
import { openSnack } from "store/common/appSlice";
import { useParams } from "react-router-dom";

interface Designation {
  id: number;
  designation: string;
}

interface AssignedTo {
  id: number;
  assigned_to: string;
}
interface Team {
  id: number;
  team_name: string;
}
interface Role {
  id: number;
  role: string;
}
interface Location {
  id: number;
  name: string;
}

interface UserCardProps {
  id?: number;
  first_name?: string;
  last_name?: string;
  profile_picture?: string;
  designation?: string;
  team_name?: string;
  location?: string;
  name?: string;
  email?: string;
  assigned_to?: string;
  employee_code?: string;
  date_of_joining?: string;
  role?: string;
  address?: string;
  skills?: string;
  designation_id?: number;
  assigned_to_id?: number;
  team_name_id?: number;
}

const EditUserPopup: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const initialUserData: UserCardProps = {
    id: 0,
    first_name: "",
    last_name: "",
    designation: "",
    team_name: "",
    location: "",
    name: "",
    email: "",
    assigned_to: "",
    employee_code: "",
    date_of_joining: "",
    role: "",
    address: "",
    designation_id: 1,
    assigned_to_id: 1,
    team_name_id: 1,
  };

  const [data, setData] = useState<UserCardProps>(initialUserData);
  const { employeeId } = useParams<{ employeeId: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await ViewProfileByIdAPI(employeeId);
        setData(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [employeeId]);

  const [desig, setDesig] = useState<Designation[]>([]);
  const [team, setTeam] = useState<Team[]>([]);
  const [pm, setPm] = useState<AssignedTo[]>([]);
  const [locations, setLocations] = useState<Location[]>([
    { id: 1, name: "Cochin" },
    { id: 2, name: "Trivandrum" },
    { id: 3, name: "Remote" },
  ]);
  const [roles, setRoles] = useState<Role[]>([
    { id: 1, role: "HR" },
    { id: 2, role: "BDM" },
    { id: 3, role: "TL" },
    { id: 4, role: "PM" },
    { id: 5, role: "TeamMember" },
  ]);
  const [imageUploadStatus, setImageUploadStatus] = useState<string | null>(null);

  const classes = useStyles();
  const { snackComponent } = useSnackBar();

  const showSnackbar = (type: string, message: string) => {
    dispatch({ type: openSnack, payload: { type, message, visibility: true } });
  };

  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(editSchema),
  });

  const { handleSubmit } = methods;

  const onSubmitError = async (error: any) => {
    showSnackbar("error", "Oops!! You've missed something. Please check once again");
    const firstErrorField = Object.keys(error)[0];
    document.getElementsByName(firstErrorField)[0].focus();
    document.getElementsByName(firstErrorField)[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const onSubmit = async (data: any) => {
    try {
      data.skills = data?.skills?.join(", ") || data.skills;
      if (data.profile_picture === "") {
        delete data.profile_picture;
      }
      await UpdateEmployeeAPI({ id: employeeId, data }).then((response) => {
        if (response.status) {
          dispatch(IsUserUpdated(true));
          showSnackbar("success", "User Updated successfully!");
        } else {
          showSnackbar("error", "Failed to update user. Please try again.");
        }
      });
      onClose();
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("error", "An error occurred. Please try again.");
    }
  };

  const { t } = useTranslation(["userDrawer", "common"]);

  const listAllDesigAPI = async () => {
    try {
      const response = await AllDesigAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllDepAPI = async () => {
    try {
      const response = await AllDepAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const listAllPmAPI = async () => {
    try {
      const response = await AllPmAPI({});
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    listAllDesigAPI().then((response) => {
      setDesig(response?.data);
    });
    listAllDepAPI().then((response) => {
      setTeam(response?.data);
    });
    listAllPmAPI().then((response) => {
      setPm(response?.data);
    });
  }, []);

  return (
    <>
      {data.id !== 0 && (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit, onSubmitError)}>
            <Box className={classes.spaceBetween}>
              <Typography className={classes.label}>{t("upphoto")}</Typography>
              <FileUploadButton
                defaultValue={data.profile_picture}
                label="Upload file"
                name="profile_picture"
                type="file"
                onUploadStatusChange={(status) => setImageUploadStatus(status)}
              />
              {imageUploadStatus && (
                <Typography variant="caption" color="success">
                  {imageUploadStatus}
                </Typography>
              )}
            </Box>
            <Box id="firstname" className={classes.spaceBetween}>
              <InputController name="first_name" label={"First Name"} placeHolder={"Ex. Luke"} defaultValue={data.first_name} isRequired />
            </Box>
            <Box className={classes.spaceBetween}>
              <InputController name="last_name" label={"Last Name"} placeHolder={"Ex. Mark"} defaultValue={data.last_name} isRequired />
            </Box>
            {/* <Box className={classes.spaceBetween}>
              <InputController name="email" label={"Email ID"} placeHolder={"Ex.luke@gmail.com"} defaultValue={data.email} isRequired />
            </Box> */}
            <Box className={classes.spaceBetween}>
              <InputController
                name="employee_code"
                label={"Employee Code"}
                placeHolder={"Ex.TVXXXX"}
                defaultValue={data.employee_code}
                isRequired
                // prefix="TV"
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <DropdownController
                arrayData={desig}
                defaultValue={data.designation_id}
                name={"designation"}
                label={"Designation"}
                dataKey={"designation"}
                isId={true}
                isRequired
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <DropdownController
                arrayData={team}
                defaultValue={data.team_name_id}
                name={"team_name"}
                label={"Department"}
                dataKey={"team_name"}
                isId={true}
                isRequired
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <DropdownController
                arrayData={pm}
                defaultValue={data.assigned_to_id}
                name={"pm"}
                label={"Reporting Manager"}
                dataKey={"assigned_to"}
                isId={true}
                isRequired
              />
            </Box>
            <ChipInput defaultSkills={[]} label="Skill" dataKey="skills" defaultValue={data.skills} isRequired />
            <Box className={classes.spaceBetween}>
              <DropdownController
                arrayData={locations}
                defaultValue={data.location}
                name={"name"}
                label={"Location"}
                dataKey={"location"}
                isRequired
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <DropdownController arrayData={roles} defaultValue={data.role} name={"role"} label={"Role"} dataKey={"role"} isRequired />
            </Box>

            <Box className={classes.spaceBetween}>
              <InputController
                name="address"
                label={"Address"}
                placeHolder={"Ex. Luke s"}
                defaultValue={data.address}
                ismultiline={true}
                isRequired
              />
            </Box>
            <Box className={classes.spaceBetween}>
              <ButtonController name="submit" label="Submit" />
              {snackComponent()}
            </Box>
          </form>
        </FormProvider>
      )}
    </>
  );
};

export default EditUserPopup;
