import { Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { snackStateSelector } from "store/common/appSlice";
import { useToggleSnack } from "./useToggleSnack";
// custom hook example
export const useSnackBar = () => {
  const { type, visibility, message } = useSelector(snackStateSelector);
  const { close } = useToggleSnack();

  const snackComponent = () => {
    return (
      <Snackbar open={visibility} autoHideDuration={2000} onClose={close} message={message}>
        <Alert onClose={close} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    );
  };
  return { snackComponent };
};
