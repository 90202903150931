import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export default makeStyles((theme: Theme) => ({
  filterContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    "& > *": {
      marginRight: theme.spacing(2),
    },
    justifyContent: "space-between",
  },
  paginateContainer: {
    justifyContent: "center",
    marginBlock: "20px",
    alignItems: "end",
    marginRight: "20px",
  },
}));
